const BigDataStructure = {
  dcp: {
    aspect_ratio: {
      _1_85: 0,
    },
    edit_rate: {
      _24: 0,
    },
    iop: 0,
    picture_format: {
      mono: 0,
      stereo: 0,
    },
    smpte: 0,
    smpte_test: {
      a: 0,
      b: 0,
      cst: 0,
      dolby_atmos: 0,
      edcf: 0,
    },
    total: 0,
  },
  imp: {
    application: {
      _2E: 0,
    },
    aspect_ratio: {
      _16_9: 0,
    },
    edit_rate: {
      _24: 0,
    },
    hdr: 0,
    total: 0,
  },
  pkl: {
    size: {
      _0: 0,
    },
    total: 0,
  },
  use: 0,
};

export { BigDataStructure };
