<template lang="html">
  <!-- CPL Timeline Modal-->
  <div
    class="modal fade"
    v-bind:id="`CPLTimelineModal${id}`"
    tabindex="-1"
    v-bind:aria-labelledby="`CPLTimelineModal${id}`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Reel Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-for="(reel, index) in ReelList" :key="index">
            <div v-if="getValue(reel.Id) == currentReel">
              <h5>Reel {{ index + 1 }} {{ getValue(reel.Id) }}</h5>
              <template
                v-for="(asset, assetindex) in reel.AssetList"
                :key="assetindex"
              >
                <div v-if="hasKey(asset, 'MainStereoscopicPicture')">
                  <div
                    class="picture-info"
                    v-for="mainStereoscopicPicture in asset.MainStereoscopicPicture"
                    :key="mainStereoscopicPicture.Id"
                  >
                    <span class="asset_id"
                      ><b>Stereoscopic Picture</b>
                      <span
                        v-if="mainStereoscopicPicture.KeyId"
                        class="encryption"
                      >
                        Encrypted</span
                      ></span
                    >
                    <span class="asset_uuid">{{
                      mainStereoscopicPicture.Id
                    }}</span>
                    <span class="asset_data">
                      <b>Entry Point :</b>
                      {{ mainStereoscopicPicture.EntryPoint }}<br />
                      <b>Edit Rate :</b>
                      {{ frameRate(mainStereoscopicPicture.EditRate) }}
                      <b>Frame Rate :</b>
                      {{ frameRate(mainStereoscopicPicture.FrameRate) }}<br />
                      <b>Screen Aspect Ratio :</b>
                      {{ mainStereoscopicPicture.ScreenAspectRatio }}
                    </span>
                    <span class="asset_duration">
                      <b>Duration :</b>
                      {{ mainStereoscopicPicture.Duration_tc }} [{{
                        mainStereoscopicPicture.Duration
                      }}
                      Frames]<br />
                      <b>Intrinsic Duration :</b>
                      {{ mainStereoscopicPicture.IntrinsicDuration_tc }}
                      [{{ mainStereoscopicPicture.IntrinsicDuration }}
                      Frames]
                    </span>
                  </div>
                </div>
                <div v-if="hasKey(asset, 'MainPicture')">
                  <div
                    class="picture-info"
                    v-for="mainPicture in asset.MainPicture"
                    :key="mainPicture.Id"
                  >
                    <span class="asset_id"
                      ><b>Main Picture</b>
                      <span v-if="mainPicture.KeyId" class="encryption">
                        Encrypted</span
                      ></span
                    >
                    <span class="asset_uuid">{{ mainPicture.Id }}</span>
                    <span class="asset_data">
                      <b>Entry Point :</b> {{ mainPicture.EntryPoint }}<br />
                      <b>Edit Rate :</b>
                      {{ frameRate(mainPicture.EditRate) }}
                      <b>Frame Rate :</b>
                      {{ frameRate(mainPicture.FrameRate) }}<br />
                      <b>Screen Aspect Ratio :</b>
                      {{ mainPicture.ScreenAspectRatio }}
                    </span>
                    <span class="asset_duration">
                      <b>Duration :</b> {{ mainPicture.Duration_tc }} [{{
                        mainPicture.Duration
                      }}
                      Frames]<br />
                      <b>Intrinsic Duration :</b>
                      {{ mainPicture.IntrinsicDuration_tc }} [{{
                        mainPicture.IntrinsicDuration
                      }}
                      Frames]
                    </span>
                  </div>
                </div>
                <div v-if="hasKey(asset, 'MainSound')">
                  <div
                    class="audio-info"
                    v-for="mainSound in asset.MainSound"
                    :key="mainSound.Id"
                  >
                    <span class="asset_id"
                      ><b>Main Sound</b>
                      <span v-if="mainSound.KeyId" class="encryption">
                        Encrypted</span
                      ></span
                    >
                    <span class="asset_uuid">{{ mainSound.Id }}</span>
                    <span class="asset_data">
                      <b>Entry Point :</b> {{ mainSound.EntryPoint }}<br />
                      <b>Edit Rate :</b>
                      {{ frameRate(mainSound.EditRate) }}
                    </span>
                    <span class="asset_duration">
                      <b>Duration :</b> {{ mainSound.Duration_tc }} [{{
                        mainSound.Duration
                      }}
                      Frames]<br />
                      <b>Intrinsic Duration :</b>
                      {{ mainSound.IntrinsicDuration_tc }} [{{
                        mainSound.IntrinsicDuration
                      }}
                      Frames]
                    </span>
                  </div>
                </div>
                <div v-if="hasKey(asset, 'MainSubtitle')">
                  <div
                    class="subtitle-info"
                    v-for="mainSubtitle in asset.MainSubtitle"
                    :key="mainSubtitle.Id"
                  >
                    <span class="asset_id"
                      ><b>Main Subtitle</b>
                      <span v-if="mainSubtitle.KeyId" class="encryption">
                        Encrypted</span
                      ></span
                    >
                    <span class="asset_uuid">{{ mainSubtitle.Id }}</span>
                    <span class="asset_data">
                      <b>Entry Point :</b> {{ mainSubtitle.EntryPoint }}<br />
                      <b>Edit Rate :</b>
                      {{ frameRate(mainSubtitle.EditRate) }}
                    </span>
                    <span class="asset_duration">
                      <b>Duration :</b>
                      {{ mainSubtitle.Duration_tc }} [{{
                        mainSubtitle.Duration
                      }}
                      Frames]<br />
                      <b>Intrinsic Duration :</b>
                      {{ mainSubtitle.IntrinsicDuration_tc }} [{{
                        mainSubtitle.IntrinsicDuration
                      }}
                      Frames]
                    </span>
                  </div>
                </div>
                <div v-if="hasKey(asset, 'ClosedCaption')">
                  <div
                    class="subtitle-info"
                    v-for="closedCaption in asset.ClosedCaption"
                    :key="closedCaption.Id"
                  >
                    <span class="asset_id"
                      ><b>Closed Caption</b>
                      <span v-if="closedCaption.KeyId" class="encryption">
                        Encrypted</span
                      ></span
                    >
                    <span class="asset_uuid">{{ closedCaption.Id }}</span>
                    <span class="asset_data">
                      <b>Entry Point :</b> {{ closedCaption.EntryPoint }}<br />
                      <b>Edit Rate :</b>
                      {{ frameRate(closedCaption.EditRate) }}<br />
                      <b>Language :</b> {{ closedCaption.Language }}
                    </span>
                    <span class="asset_duration">
                      <b>Duration :</b>
                      {{ closedCaption.Duration_tc }} [{{
                        closedCaption.Duration
                      }}
                      Frames]<br />
                      <b>Intrinsic Duration :</b>
                      {{ closedCaption.IntrinsicDuration_tc }} [{{
                        closedCaption.IntrinsicDuration
                      }}
                      Frames]
                    </span>
                  </div>
                </div>
                <div v-if="hasKey(asset, 'AuxData')">
                  <div
                    class="auxdata-info"
                    v-for="auxData in asset.AuxData"
                    :key="auxData.Id"
                  >
                    <span class="asset_id"
                      ><b>AuxData</b>
                      <span v-if="auxData.DataName">
                        - <b>{{ auxData.DataName }} </b></span
                      >
                      <span v-if="auxData.KeyId" class="encryption">
                        Encrypted</span
                      >
                    </span>
                    <span class="asset_uuid">{{ auxData.Id }}</span>
                    <span class="asset_data">
                      <b>Entry Point :</b> {{ auxData.EntryPoint }}<br />
                      <b>Edit Rate :</b>
                      {{ frameRate(auxData.EditRate) }}
                    </span>
                    <span class="asset_duration">
                      <b>Duration :</b> {{ auxData.Duration_tc }} [{{
                        auxData.Duration
                      }}
                      Frames]<br />
                      <b>Intrinsic Duration :</b>
                      {{ auxData.IntrinsicDuration_tc }} [{{
                        auxData.IntrinsicDuration
                      }}
                      Frames]
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="timeline">
    <table>
      <tr class="horizontal-reel-tr">
        <td class="horizontal-header" style="min-width: 100px"></td>
        <td class="vertical-separator"></td>
        <td
          v-for="(reel, index) in reels"
          :key="index"
          class="horizontal-reel horizontal-reel"
        >
          <span
            @click="setCurrent(reel.id)"
            data-bs-toggle="modal"
            v-bind:data-bs-target="`#CPLTimelineModal${id}`"
            >Reel {{ index + 1 }}
            <i class="bi bi-arrow-up-right-circle"></i> Details</span
          >
        </td>
      </tr>
    </table>
    <table v-if="test('picture')">
      <tr class="horizontal-reel-tr">
        <td class="horizontal-header" style="min-width: 100px">Picture</td>
        <td class="vertical-separator"></td>
        <td
          v-for="(reel, index) in reels"
          :key="index"
          class="horizontal-image horizontal-reel"
        >
          <template v-if="hasKey(reel.picture, 'duration')">
            <span
              @click="setCurrent(reel.id)"
              data-bs-toggle="modal"
              v-bind:data-bs-target="`#CPLTimelineModal${id}`"
              >Asset {{ index + 1 }} : {{ reel.picture.duration }}</span
            >
          </template>
        </td>
      </tr>
    </table>
    <table v-if="test('sound')">
      <tr class="horizontal-reel-tr">
        <td class="horizontal-header" style="min-width: 100px">Sound</td>
        <td class="vertical-separator"></td>
        <td
          v-for="(reel, index) in reels"
          :key="index"
          class="horizontal-audio horizontal-reel"
        >
          <template v-if="hasKey(reel.sound, 'duration')">
            <span
              @click="setCurrent(reel.id)"
              data-bs-toggle="modal"
              v-bind:data-bs-target="`#CPLTimelineModal${id}`"
              >Asset {{ index + 1 }} : {{ reel.sound.duration }}</span
            >
          </template>
        </td>
      </tr>
    </table>
    <table v-if="test('subtitle')">
      <tr class="horizontal-reel-tr">
        <td class="horizontal-header" style="min-width: 100px">Subtitle</td>
        <td class="vertical-separator"></td>
        <td
          v-for="(reel, index) in reels"
          :key="index"
          class="horizontal-subtitle horizontal-reel"
        >
          <template v-if="hasKey(reel.subtitle, 'duration')">
            <span
              @click="setCurrent(reel.id)"
              data-bs-toggle="modal"
              v-bind:data-bs-target="`#CPLTimelineModal${id}`"
              >Asset {{ index + 1 }} : {{ reel.subtitle.duration }}</span
            >
          </template>
        </td>
      </tr>
    </table>
    <table v-if="test('caption')">
      <tr class="horizontal-reel-tr">
        <td class="horizontal-header" style="min-width: 100px">Caption</td>
        <td class="vertical-separator"></td>
        <td
          v-for="(reel, index) in reels"
          :key="index"
          class="horizontal-subtitle horizontal-reel"
        >
          <template v-if="hasKey(reel.caption, 'duration')">
            <span
              @click="setCurrent(reel.id)"
              data-bs-toggle="modal"
              v-bind:data-bs-target="`#CPLTimelineModal${id}`"
              >Asset {{ index + 1 }} : {{ reel.caption.duration }}</span
            >
          </template>
        </td>
      </tr>
    </table>
    <table v-if="test('auxdata')">
      <tr class="horizontal-reel-tr">
        <td class="horizontal-header" style="min-width: 100px">Auxdata</td>
        <td class="vertical-separator"></td>
        <td
          v-for="(reel, index) in reels"
          :key="index"
          class="horizontal-auxdata horizontal-reel"
        >
          <template v-if="hasKey(reel.auxdata, 'duration')">
            <span
              @click="setCurrent(reel.id)"
              data-bs-toggle="modal"
              v-bind:data-bs-target="`#CPLTimelineModal${id}`"
              >Asset {{ index + 1 }} : {{ reel.auxdata.duration }}</span
            >
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
/* FishTank 2023 */
/* CPL DCP Timeline */
import { has_key, get_value, framerate } from "../../tools/utils";
var WITHD = 150;
export default {
  name: "TimeLine",
  props: ["ReelList"],
  data() {
    return {
      id: null,
      reels: [],
      currentReel: null,
    };
  },
  created: function () {
    this.setTable();
    this.id = `${Math.floor(Math.random() * 100)}-${Math.floor(
      Math.random() * 100
    )}-${Math.floor(Math.random() * 100)}`;
  },
  methods: {
    getValue: function (value) {
      return get_value(value);
    },
    frameRate: function (value) {
      return framerate(value);
    },
    hasKey: function (x, y) {
      return has_key(x, y);
    },
    test(kind) {
      let has = [];
      this.reels.forEach((reel) => {
        if (kind == "picture" && has_key(reel, kind)) has.push(1);
        if (kind == "sound" && has_key(reel, kind)) has.push(1);
        if (kind == "subtitle" && has_key(reel, kind)) has.push(1);
        if (kind == "caption" && has_key(reel, kind)) has.push(1);
        if (kind == "auxdata" && has_key(reel, kind)) has.push(1);
      });
      return has.length != 0;
    },
    setCurrent(id) {
      this.currentReel = id;
    },
    setTable() {
      this.ReelList.forEach((reel) => {
        let reel_info = {
          width: 0,
          id: get_value(reel.Id),
        };
        reel.AssetList.forEach((asset) => {
          if (has_key(asset, "MainPicture")) {
            asset.MainPicture.forEach((item) => {
              reel_info.picture = {
                width: WITHD,
                id: get_value(item.Id),
                duration: item.Duration_tc,
              };
              if (reel_info.picture.width > reel_info.width)
                reel_info.width = reel_info.picture.width;
            });
          }
          if (has_key(asset, "MainStereoscopicPicture")) {
            asset.MainStereoscopicPicture.forEach((item) => {
              reel_info.picture = {
                width: WITHD,
                id: get_value(item.Id),
                duration: item.Duration_tc,
              };
              if (reel_info.picture.width > reel_info.width)
                reel_info.width = reel_info.picture.width;
            });
          }
          if (has_key(asset, "MainSound")) {
            asset.MainSound.forEach((item) => {
              reel_info.sound = {
                width: WITHD,
                id: get_value(item.Id),
                duration: item.Duration_tc,
              };
              if (reel_info.sound.width > reel_info.width)
                reel_info.width = reel_info.sound.width;
            });
          }
          if (has_key(asset, "MainSubtitle")) {
            asset.MainSubtitle.forEach((item) => {
              reel_info.subtitle = {
                width: WITHD,
                id: get_value(item.Id),
                duration: item.Duration_tc,
              };
              if (reel_info.subtitle.width > reel_info.width)
                reel_info.width = reel_info.subtitle.width;
            });
          }
          if (has_key(asset, "ClosedCaption")) {
            asset.ClosedCaption.forEach((item) => {
              reel_info.caption = {
                width: WITHD,
                id: get_value(item.Id),
                duration: item.Duration_tc,
              };
              if (reel_info.caption.width > reel_info.width)
                reel_info.width = reel_info.caption.width;
            });
          }
          if (has_key(asset, "AuxData")) {
            asset.AuxData.forEach((item) => {
              reel_info.auxdata = {
                width: WITHD,
                id: get_value(item.Id),
                duration: item.Duration_tc,
              };
              if (reel_info.auxdata.width > reel_info.width)
                reel_info.width = reel_info.auxdata.width;
            });
          }
        });
        this.reels.push(reel_info);
      });
    },
  },
};
</script>
