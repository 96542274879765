<template lang="html">
  <div>
    <div v-for="(descriptor_list, id) in descriptors" :key="id">
      <div v-for="(descriptor, id) in descriptor_list" :key="id">
        <div v-if="descriptor.Id == sourceencoding">
          <div v-for="(_, key) in descriptor" :key="key">
            <div v-if="key == 'CDCIDescriptor'">
              <a v-if="modal_value == false" @click="modal_value = true"
                ><b
                  >CDCI Descriptors
                  <small><i class="bi bi-file-plus"></i></small></b
              ></a>
              <div v-if="modal_value">
                <a data-dismiss="modal" @click="modal_value = false"
                  ><b
                    >CDCI Descriptors
                    <small><i class="bi bi-file-minus"></i></small></b></a
                ><br /><br />
                <b>Source Encoding :</b> {{ sourceencoding }}<br />
                <SimplePrettyPrint
                  v-bind:data="descriptor.CDCIDescriptor"
                  v-bind:indentation="0"
                ></SimplePrettyPrint>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* FishTank 2023 */
/* CPL IMP markers module */
export default {
  name: "CDCIDescriptor",
  props: ["descriptors", "sourceencoding"],
  components: {
    SimplePrettyPrint: require("../../tools/SimplePrettyPrint.vue"),
  },
  data() {
    return {
      modal_value: false,
    };
  },
};
</script>
