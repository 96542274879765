/* FishTank 2023
   CPL DPC / IMP PDF generator
*/

import { yesno, pictureformat, framerate, clean_key, get_value } from "./utils";

function format_imp_pdf(
  cpl,
  imf_application,
  markers_list,
  duration,
  aspect_ratio
) {
  let pdf = {
    header: {
      text: "CPL | Translator",
      alignment: "right",
      margin: 5,
      fontSize: 7,
    },
    footer: {
      text: "https://cpl.fishtank.cloud - © 2023 FishTank Cloud - A division of Trench Digital LTD.",
      alignment: "center",
      margin: 5,
      fontSize: 6,
    },
    watermark: {
      text: "CPL Translator",
      color: "black",
      opacity: 0.03,
      bold: false,
      italics: false,
    },
    content: [],
  };
  pdf.content.push({
    text: "IMP - Main Information",
    bold: true,
    margin: [0, 2],
    fontSize: 20,
  });
  pdf.content.push({
    columns: [
      {
        text: "Content Title",
        bold: true,
        width: 140,
      },
      get_value(cpl.ContentTitle),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "File Name",
        bold: true,
        width: 140,
      },
      cpl.fileName,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "UUID",
        bold: true,
        width: 140,
      },
      cpl.Id,
    ],
  });
  pdf.content.push({
    text: " ",
    fontSize: 10,
  });
  pdf.content.push({
    text: "Details",
    bold: true,
    margin: [0, 2],
    fontSize: 20,
  });
  pdf.content.push({
    columns: [
      {
        text: "Edit Rate",
        bold: true,
        width: 140,
      },
      `${String(cpl.EditRate)} FPS`,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "High Frame Rate",
        bold: true,
        width: 140,
      },
      yesno(cpl.hfr),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Nb of Segments",
        bold: true,
        width: 140,
      },
      String(cpl.SegmentList.length),
    ],
  });
  if (aspect_ratio != null) {
    pdf.content.push({
      columns: [
        {
          text: "Aspect Ratio",
          bold: true,
          width: 140,
        },
        get_value(aspect_ratio),
      ],
    });
  }
  pdf.content.push({
    columns: [
      {
        text: "Duration",
        bold: true,
        width: 140,
      },
      duration,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Content Kind",
        bold: true,
        width: 140,
      },
      get_value(cpl.ContentKind),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Picture Format",
        bold: true,
        width: 140,
      },
      pictureformat(cpl.stereoscopic),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Content Originator",
        bold: true,
        width: 140,
      },
      get_value(cpl.ContentOriginator),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Issue Date",
        bold: true,
        width: 140,
      },
      get_value(cpl.IssueDate),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Issuer",
        bold: true,
        width: 140,
      },
      get_value(cpl.Issuer),
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: "Application",
        bold: true,
        width: 140,
      },
      imf_application,
    ],
  });
  if (cpl.definition != null) {
    pdf.content.push({
      columns: [
        {
          text: "Definition",
          bold: true,
          width: 140,
        },
        get_value(cpl.definition),
      ],
    });
  }
  if (cpl.aspect_ratio != null) {
    pdf.content.push({
      columns: [
        {
          text: "Aspect Ratio",
          bold: true,
          width: 140,
        },
        get_value(cpl.aspect_ratio),
      ],
    });
  }
  pdf.content.push({
    columns: [
      {
        text: "Subtitles",
        bold: true,
        width: 140,
      },
      yesno(cpl.subtitles),
    ],
  });

  if (markers_list.length > 0) {
    pdf.content.push({
      text: " ",
      fontSize: 10,
    });
    pdf.content.push({
      text: "Markers",
      bold: true,
      margin: [0, 2],
      fontSize: 20,
    });
    let body_makers = [["Label", "Timecode", "Offset"]];
    markers_list.forEach(function (element) {
      body_makers.push([
        `${element.Label}`,
        `${element.tc}`,
        `${element.Offset}`,
      ]);
    });
    pdf.content.push({
      layout: "lightHorizontalLines", // optional
      table: {
        headerRows: 1,
        widths: ["auto", "auto", "auto"],
        body: body_makers,
      },
    });
  }
  pdf.content.push({
    text: `Segment list ${cpl.SegmentList.length}`,
    bold: true,
    margin: [0, 2],
    fontSize: 20,
    pageBreak: "before",
  });

  cpl.SegmentList.forEach((segmentList) => {
    segmentList.Segment.forEach((segment) => {
      pdf.content.push({
        text: `Segment ID ${segment.Id} ${segment.Annotation}`,
        bold: true,
        margin: [0, 2],
        fontSize: 14,
      });
      segment.SequenceList.forEach((sequence) => {
        if (
          Object.prototype.hasOwnProperty.call(sequence, "MainImageSequence")
        ) {
          sequence.MainImageSequence.forEach((mainImageSequence, index) => {
            let body_resource = [];
            body_resource.push([
              `Image Sequence ${index + 1}`,
              `${mainImageSequence.Id}`,
            ]);
            pdf.content.push({
              margin: [0, 4],
              layout: "lightHorizontalLines", // optional
              table: {
                widths: ["auto", "auto"],
                body: body_resource,
              },
            });
            pdf.content.push({ text: " ", fontSize: 10 });
            mainImageSequence.ResourceList.forEach((resource, index) => {
              let body_resource = [];
              body_resource.push([
                `Image Resource ${index + 1}`,
                `${resource.Id}`,
              ]);
              body_resource.push([
                `Source Encoding`,
                `${resource.SourceEncoding}`,
              ]);
              body_resource.push([`Track File Id`, `${resource.TrackFileId}`]);
              body_resource.push([`Edit Rate`, `${resource.EditRate}`]);
              body_resource.push([
                `Timecode`,
                `In: ${resource.in_complete_tc} Out: ${resource.out_complete_tc} Duration: ${resource.SourceDuration_tc}`,
              ]);
              pdf.content.push({
                margin: [20, 4],
                layout: "lightHorizontalLines", // optional
                table: {
                  widths: ["auto", "auto"],
                  body: body_resource,
                },
              });
            });
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(sequence, "MainAudioSequence")
        ) {
          sequence.MainAudioSequence.forEach((mainAudioSequence, index) => {
            let body_resource = [];
            body_resource.push([
              `Image Sequence ${index + 1}`,
              `${mainAudioSequence.Id}`,
            ]);
            pdf.content.push({
              margin: [0, 4],
              layout: "lightHorizontalLines", // optional
              table: {
                widths: ["auto", "auto"],
                body: body_resource,
              },
            });
            pdf.content.push({ text: " ", fontSize: 10 });
            mainAudioSequence.ResourceList.forEach((resource, index) => {
              let body_resource = [];
              body_resource.push([
                `Audio Resource ${index + 1}`,
                `${resource.Id}`,
              ]);
              body_resource.push([
                `Source Encoding`,
                `${resource.SourceEncoding}`,
              ]);
              body_resource.push([`Track File Id`, `${resource.TrackFileId}`]);
              body_resource.push([`Edit Rate`, `${resource.EditRate}`]);
              body_resource.push([
                `Timecode`,
                `In: ${resource.in_complete_tc} Out: ${resource.out_complete_tc} Duration: ${resource.SourceDuration_tc}`,
              ]);
              pdf.content.push({
                margin: [20, 4],
                layout: "lightHorizontalLines", // optional
                table: {
                  widths: ["auto", "auto"],
                  body: body_resource,
                },
              });
            });
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(sequence, "SubtitlesSequence")
        ) {
          sequence.SubtitlesSequence.forEach((subtitlesSequence, index) => {
            let body_resource = [];
            body_resource.push([
              `Image Sequence ${index + 1}`,
              `${subtitlesSequence.Id}`,
            ]);
            pdf.content.push({
              margin: [0, 4],
              layout: "lightHorizontalLines", // optional
              table: {
                widths: ["auto", "auto"],
                body: body_resource,
              },
            });
            pdf.content.push({ text: " ", fontSize: 10 });
            subtitlesSequence.ResourceList.forEach((resource, index) => {
              let body_resource = [];
              body_resource.push([
                `Audio Resource ${index + 1}`,
                `${resource.Id}`,
              ]);
              body_resource.push([
                `Source Encoding`,
                `${resource.SourceEncoding}`,
              ]);
              body_resource.push([`Track File Id`, `${resource.TrackFileId}`]);
              body_resource.push([`Edit Rate`, `${resource.EditRate}`]);
              body_resource.push([
                `Timecode`,
                `In: ${resource.in_complete_tc} Out: ${resource.out_complete_tc} Duration: ${resource.SourceDuration_tc}`,
              ]);
              pdf.content.push({
                margin: [20, 4],
                layout: "lightHorizontalLines", // optional
                table: {
                  widths: ["auto", "auto"],
                  body: body_resource,
                },
              });
            });
          });
        }
      });
    });
  });
  return pdf;
}

function format_data(data, padding) {
  let content = [];
  padding = padding + 20;
  for (let [key, value] of Object.entries(data)) {
    if (clean_key(key) != "") {
      if (key == "EditRate") value = framerate(value);
      if (key == "FrameRate") value = framerate(value);
      if (key == "Encrypted") value = yesno(value);
      if (value != "") {
        if (Array.isArray(value)) {
          value.forEach((element) => {
            content = content.concat(format_data(element, padding));
          });
        } else {
          if (String(value) != "[object Object]") {
            content.push({
              columns: [
                {
                  text: clean_key(key),
                  bold: true,
                  width: "auto",
                  margin: [padding, 0, 0, 0],
                },
                `${value}`,
              ],
            });
          } else {
            content.push({
              columns: [
                {
                  text: clean_key(key),
                  bold: true,
                  width: "auto",
                  margin: [padding, 0, 0, 0],
                },
              ],
            });
            content = content.concat(format_data(value, padding + 20));
          }
        }
      }
    }
  }
  return content;
}

function format_dcp_pdf(cpl) {
  let pdf = {
    header: {
      text: "CPL | Translator",
      alignment: "right",
      margin: 5,
      fontSize: 7,
    },
    footer: {
      text: "https://cpl.fishtank.cloud - © 2023 FishTank Cloud - A division of Trench Digital LTD.",
      alignment: "center",
      margin: 5,
      fontSize: 6,
    },
    watermark: {
      text: "CPL Translator",
      color: "black",
      opacity: 0.03,
      bold: false,
      italics: false,
    },
    content: [],
  };
  pdf.content.push({
    text: "DCP - Main Information",
    bold: true,
    margin: [0, 2],
    fontSize: 20,
  });
  pdf.content.push({
    columns: [
      {
        text: "Title",
        bold: true,
        width: 140,
      },
      cpl.film_title,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Content Title",
        bold: true,
        width: 140,
      },
      cpl.ContentTitleText,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "File Name",
        bold: true,
        width: 140,
      },
      cpl.fileName,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "UUID",
        bold: true,
        width: 140,
      },
      cpl.Id,
    ],
  });
  pdf.content.push({
    text: " ",
    bold: true,
    margin: [0, 2],
    fontSize: 20,
  });
  pdf.content.push({
    text: "Details",
    bold: true,
    margin: [0, 2],
    fontSize: 20,
  });
  pdf.content.push({
    columns: [
      {
        text: "Edit Rate",
        bold: true,
        width: 140,
      },
      `${String(cpl.edit_rate)} FPS`,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Frame Rate",
        bold: true,
        width: 140,
      },
      `${String(cpl.frame_rate)} FPS`,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "High Frame Rate",
        bold: true,
        width: 140,
      },
      yesno(cpl.hfr),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "No of Reels",
        bold: true,
        width: 140,
      },
      String(cpl.ReelList.length),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Aspect Ratio",
        bold: true,
        width: 140,
      },
      cpl.aspect_ratio.d,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Duration",
        bold: true,
        width: 140,
      },
      cpl.duration.tc,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Content Kind",
        bold: true,
        width: 140,
      },
      get_value(cpl.ContentKind),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Picture Format",
        bold: true,
        width: 140,
      },
      pictureformat(cpl.stereoscopic),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "DCP Type",
        bold: true,
        width: 140,
      },
      cpl.content_type,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Encryption",
        bold: true,
        width: 140,
      },
      yesno(cpl.encryption),
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Issuer Creator",
        bold: true,
        width: 140,
      },
      `${cpl.Issuer} ${cpl.Creator}`,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Namespace",
        bold: true,
        width: 140,
      },
      cpl._xmlns,
    ],
  });
  pdf.content.push({
    columns: [
      {
        text: "Signature",
        bold: true,
        width: 140,
      },
      yesno(cpl.signature),
    ],
  });

  if (cpl.time_text_subtitle) {
    pdf.content.push({
      columns: [
        {
          text: "Timed Text Subtitles",
          bold: true,
          width: 140,
        },
        `YES Encryption : ${yesno(cpl.time_text_subtitle_encrypted)}`,
      ],
    });
  } else {
    pdf.content.push({
      columns: [
        {
          text: "Timed Text Subtitles",
          bold: true,
          width: 140,
        },
        "NO",
      ],
    });
  }

  pdf.content.push({
    columns: [
      {
        text: "Composition Metadata Asset",
        bold: true,
        width: 140,
      },
      yesno(cpl.composition_metadata),
    ],
  });

  if (cpl.chip_list.length > 0) {
    let p = [];
    cpl.chip_list.forEach((element) => {
      if (element.value != "YES") {
        p.push(element.value);
      }
    });
    pdf.content.push({
      columns: [
        {
          text: "Extensions",
          bold: true,
          width: 140,
        },
        p.join(" and "),
      ],
    });
  }

  pdf.content.push({
    columns: [
      {
        text: "Markers",
        bold: true,
        width: 140,
      },
      yesno(cpl.markers),
    ],
  });

  pdf.content.push({
    columns: [
      {
        text: "Rating Agency & Label",
        bold: true,
        width: 140,
      },
      yesno(cpl.RatingList),
    ],
  });

  pdf.content.push({
    text: " ",
    bold: true,
    margin: [0, 2],
    fontSize: 20,
  });
  if (cpl.marker_list.length > 0) {
    pdf.content.push({
      text: "Marker",
      bold: true,
      margin: [0, 2],
      fontSize: 20,
    });
    cpl.marker_list.forEach(function (element) {
      pdf.content.push({
        text: `${element.Label} ${element.Offset}`,
      });
    });
  }
  if (cpl.content_type == "SMPTE") {
    pdf.content.push({
      text: " ",
      bold: true,
      margin: [0, 2],
      fontSize: 20,
    });
    pdf.content.push({
      text: "DCP Test",
      bold: true,
      margin: [0, 2],
      fontSize: 20,
    });
    let dcp_test = {
      columns: [
        {
          text: "ISDCF : " + cpl.test.ISDCF,
          bold: true,
        },
      ],
    };
    if (cpl.test.ISDCF == "SMPTE B" && cpl.test.EDCF != "KO") {
      dcp_test.columns.push({
        text: "EDCF : OK",
        bold: true,
      });
    }
    if (cpl.test.ISDCF == "SMPTE B" && cpl.test.CST != "KO") {
      dcp_test.columns.push({
        text: "CST : OK",
        bold: true,
      });
    }
    pdf.content.push(dcp_test);
  }

  pdf.content.push({
    text: "Reel list",
    bold: true,
    margin: [0, 2],
    fontSize: 20,
    pageBreak: "before",
  });

  cpl.ReelList.forEach((reel, i) => {
    if (reel.AssetList.CompositionMetadataAsset) {
      pdf.content.push({
        text: "Composition Metadata Asset",
        bold: true,
        margin: [0, 8, 0, 0],
      });
      pdf.content = pdf.content.concat(
        format_data(reel.AssetList.CompositionMetadataAsset, 0)
      );
    }

    pdf.content.push({
      text: `Reel ${i + 1}`,
      bold: true,
      fontSize: 16,
      margin: [0, 10, 0, 0],
    });
    if (reel.AssetList.MainStereoscopicPicture) {
      pdf.content.push({
        text: "Stereoscopic Picture",
        bold: true,
        margin: [0, 8, 0, 0],
      });
      for (let [key, value] of Object.entries(
        reel.AssetList.MainStereoscopicPicture
      )) {
        if (typeof value === "string") {
          if (key == "EditRate") value = framerate(value);
          if (key == "FrameRate") value = framerate(value);
          if (key == "Encrypted") value = yesno(value);
          pdf.content.push({
            columns: [
              {
                text: clean_key(key),
                bold: true,
                width: 140,
                margin: [20, 0, 0, 0],
              },
              value,
            ],
          });
        }
      }
    }
    if (reel.AssetList.MainPicture) {
      pdf.content.push({
        text: "Picture",
        bold: true,
        margin: [0, 8, 0, 0],
      });
      for (let [key, value] of Object.entries(reel.AssetList.MainPicture)) {
        if (typeof value === "string") {
          if (key == "EditRate") value = framerate(value);
          if (key == "FrameRate") value = framerate(value);
          if (key == "Encrypted") value = yesno(value);
          pdf.content.push({
            columns: [
              {
                text: clean_key(key),
                bold: true,
                width: 140,
                margin: [20, 0, 0, 0],
              },
              value,
            ],
          });
        }
      }
    }
    if (reel.AssetList.MainSound) {
      pdf.content.push({
        text: "Sound",
        bold: true,
        margin: [0, 8, 0, 0],
      });
      for (let [key, value] of Object.entries(reel.AssetList.MainSound)) {
        if (typeof value === "string") {
          if (key == "EditRate") value = framerate(value);
          if (key == "FrameRate") value = framerate(value);
          if (key == "Encrypted") value = yesno(value);
          pdf.content.push({
            columns: [
              {
                text: clean_key(key),
                bold: true,
                width: 140,
                margin: [20, 0, 0, 0],
              },
              value,
            ],
          });
        }
      }
    }
    if (reel.AssetList.MainSubtitle) {
      pdf.content.push({
        text: "Subtitle",
        bold: true,
        margin: [0, 8, 0, 0],
      });
      for (let [key, value] of Object.entries(reel.AssetList.MainSubtitle)) {
        if (typeof value === "string") {
          if (key == "EditRate") value = framerate(value);
          if (key == "FrameRate") value = framerate(value);
          if (key == "Encrypted") value = yesno(value);
          pdf.content.push({
            columns: [
              {
                text: clean_key(key),
                bold: true,
                width: 140,
                margin: [20, 0, 0, 0],
              },
              value,
            ],
          });
        }
      }
    }
    if (reel.AssetList.AuxData) {
      pdf.content.push({
        text: "AuxData",
        bold: true,
        margin: [0, 8, 0, 0],
      });
      for (let [key, value] of Object.entries(reel.AssetList.AuxData)) {
        if (typeof value === "string") {
          if (key == "EditRate") value = framerate(value);
          if (key == "FrameRate") value = framerate(value);
          if (key == "Encrypted") value = yesno(value);
          pdf.content.push({
            columns: [
              {
                text: clean_key(key),
                bold: true,
                width: 140,
                margin: [20, 0, 0, 0],
              },
              value,
            ],
          });
        }
      }
    }
  });
  return pdf;
}
export { format_dcp_pdf, format_imp_pdf };
