<!-- FishTank 2023-->
<template lang="html">
  <nav
    class="navbar navbar-dark bg-dark navbar-expand-lg py-3 sticky-top"
    v-if="logged"
  >
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <span style="color: #ddd">Delete my account : </span>
            <input v-model="message" placeholder="DELETE" />
          </li>
          <li
            class="nav-item btn btn-outline-danger"
            v-if="userDelete"
            @click="deleteAccount()"
          >
            Confirm
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container-fluid bg" v-if="logged">
    <div class="row">
      <div class="col-12">
        <div class="side" v-if="userData != null">
          <h3>My stats</h3>
          <div class="chip">
            <div class="chiplegend">Total use</div>
            <div class="chipbigb">{{ userData.use }}</div>
          </div>
          <div class="chip">
            <div class="chiplegend">Total DCP</div>
            <div class="chipbigb">{{ userData.dcp.total }}</div>
          </div>
          <div class="chip">
            <div class="chiplegend">Total IMP</div>
            <div class="chipbigb">{{ userData.imp.total }}</div>
          </div>
          <div class="chip">
            <div class="chiplegend">Total PKL</div>
            <div class="chipbigb">{{ userData.pkl.total }}</div>
          </div>
          <h3>DCP</h3>
          <div class="chip">
            <div class="chiplegend">INTEROP DCP</div>
            <div class="chipbigb">
              {{ userData.dcp.total - userData.dcp.smpte }}
            </div>
          </div>
          <div class="chip">
            <div class="chiplegend">SMPTE DCP</div>
            <div class="chipbigb">{{ userData.dcp.smpte }}</div>
          </div>
          <template
            v-for="(count, test) in userData.dcp.smpte_test"
            :key="test"
          >
            <div class="chip">
              <div class="chiplegend">
                SMPTE {{ test.replace("_", " ").toUpperCase() }}
              </div>
              <div class="chipbigy">
                {{ count }}
              </div>
            </div>
          </template>
          <h3>IMP</h3>
          <template v-for="(count, app) in userData.imp.application" :key="app">
            <div class="chip">
              <div class="chiplegend">
                Application {{ app.replace("_", "") }}
              </div>
              <div class="chipbigblack">
                {{ count }}
              </div>
            </div>
          </template>
        </div>
        <div class="side" v-else>
          <h3>My stats</h3>
          No user data found.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../firebase/index";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import router from "@/router";

const firbaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firbaseApp);
const firbaseDatabase = getDatabase(firbaseApp);

export default {
  name: "UserInfo",
  data() {
    return {
      message: "",
      userData: null,
      userDelete: false,
      otherData: {
        use: 0,
        dcp: 0,
        imp: 0,
        pkl: 0,
      },
      logged: false,
    };
  },
  watch: {
    message: {
      handler() {
        if (this.message.toLowerCase() == "delete") {
          this.userDelete = true;
        } else {
          this.userDelete = false;
        }
      },
      deep: true,
    },
  },
  created() {
    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        this.getUserData();
        this.logged = true;
      }
    });
  },
  methods: {
    deleteAccount() {
      this.logged = false;
      this.$notify({
        text: "User is deleted.",
        type: "info",
        fullWidth: true,
        duration: 3000,
      });
      firebaseAuth.currentUser
        .delete()
        .then(function () {
          router.go("/");
          ref(
            firbaseDatabase,
            `users/${firebaseAuth.currentUser.uid}`
          ).remove();
        })
        .catch(function () {
          router.go("/");
        });
      this.$forceUpdate();
    },
    getUserData() {
      const currentUserData = ref(
        firbaseDatabase,
        `users/${firebaseAuth.currentUser.uid}`
      );
      onValue(currentUserData, (snapshot) => {
        if (snapshot.exists) {
          this.userData = snapshot.val();
        }
      });
    },
  },
};
</script>
