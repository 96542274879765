<script setup>
import PrettyPrint from "../tools/PrettyPrint.vue";
import MarkersInfo from "./MarkersInfo.vue";
import TimeLine from "./imp/TimeLine.vue";
</script>

<template lang="html">
  <div>
    <div
      class="side"
      v-if="iscpl"
      :key="getValue(cpl.Id)"
      v-bind:id="getValue(cpl.Id)"
    >
      <div class="imp_logo"></div>
      <h3>CPL - Main Information</h3>
      <div v-if="imf_application.num == 'unknown'">
        <center>
          <h4>IMF application unknown</h4>
          {{ imf_application.name }}
        </center>
      </div>
      <div v-else>
        <center>
          <h4>IMF application</h4>
          <div class="chipbigb">{{ imf_application.num }}</div>
          <br />
          <a :href="imf_application.url" target="_blank">
            {{ imf_application.description }}
          </a>
          <br />
          {{ imf_application.xmlns }}
        </center>
      </div>
      <a v-if="applicationModal == false" @click="applicationModal = true">
        <h5>
          Applications <small><i class="bi bi-file-plus"></i></small>
        </h5>
      </a>
      <div class="width-text" v-if="applicationModal">
        <a data-dismiss="modal" @click="applicationModal = false">
          <h5>
            Applications <small><i class="bi bi-file-minus"></i></small>
          </h5>
        </a>
        <table class="table table-sm">
          <tbody>
            <tr v-for="(application, i) in applicationDescription" :key="i">
              <td>
                <b>{{ application.num }}</b>
              </td>
              <td>
                <a :href="application.url" target="_blank">{{
                  application.description
                }}</a>
                <br />
                {{ application.xmlns }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <a v-if="imfModal == false" @click="imfModal = true">
        <h5>
          IMF <small><i class="bi bi-file-plus"></i></small>
        </h5>
      </a>
      <div class="width-text" v-if="imfModal">
        <a data-dismiss="modal" @click="imfModal = false">
          <h5>
            IMF <small><i class="bi bi-file-minus"></i></small>
          </h5>
        </a>
        <a href="http://www.imfug.com" target="_blank">IMF User Group</a><br />
        IMF is a file-based framework that allows the creation of high-quality
        master versions, called Compositions. These compositions are a
        combination of modular based metadata and referenced image, audio and
        timed text essence data in an ordered sequence so that they can be
        played back correctly as intended. Compositions also enable a file-based
        system to manage, process and transform it to any other foreseeable file
        format for downstream distribution platforms.
      </div>
      <br />
      <button class="btn btn-outline-secondary" @click="pdfExport">
        PDF Export
      </button>
      <br /><br />
      <table class="table table-sm">
        <tbody>
          <tr>
            <td>Content Title</td>
            <td>{{}}</td>
          </tr>
          <tr>
            <td>File Name</td>
            <td>{{ cpl.fileName }}</td>
          </tr>
          <tr>
            <td>Annotation Text</td>
            <td>{{ getValue(cpl.Annotation) }}</td>
          </tr>
          <tr>
            <td>Issuer</td>
            <td>{{ getValue(cpl.Issuer) }}</td>
          </tr>
          <tr>
            <td>Creator</td>
            <td>{{ getValue(cpl.Creator) }}</td>
          </tr>
          <tr>
            <td>Content Originator</td>
            <td>{{ getValue(cpl.ContentOriginator) }}</td>
          </tr>
          <tr>
            <td>Content Kind</td>
            <td>{{ getValue(cpl.ContentKind) }}</td>
          </tr>
          <tr v-if="cpl.ContentVersionList">
            <td>Content Version</td>
            <td>
              <template v-for="(item, i) in cpl.ContentVersionList" :key="i">
                <p v-for="(content_version, j) in item.ContentVersion" :key="j">
                  ID : {{ getValue(content_version.Id) }}<br />
                  Label Text : {{ getValue(content_version.LabelText) }}
                </p>
              </template>
            </td>
          </tr>
          <tr>
            <td>Issue Date</td>
            <td>{{ getValue(cpl.IssueDate) }}</td>
          </tr>
          <tr>
            <td>UUID</td>
            <td>{{ getValue(cpl.Id) }}</td>
          </tr>
          <tr>
            <td>Namespace</td>
            <td>{{ cpl.$.xmlns }}</td>
          </tr>
          <template v-if="cpl.CompositionTimecode">
            <template v-for="(ct, i) in cpl.CompositionTimecode" :key="i">
              <tr>
                <td>Composition Timecode Drop Frame</td>
                <td>
                  {{ getValue(ct.TimecodeDropFrame) }}
                </td>
              </tr>
              <tr>
                <td>Composition Timecode Rate</td>
                <td>{{ getValue(ct.TimecodeRate) }} FPS</td>
              </tr>
              <tr>
                <td>Composition Timecode Start Address</td>
                <td>{{ getValue(ct.TimecodeStartAddress) }}</td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
      <br />
      <h3>Details</h3>
      <center>
        <div class="chip">
          <div class="chiplegend">Edit Rate</div>
          <div class="chipbigb">
            {{ frameRate(getValue(cpl.EditRate)) }} FPS
          </div>
        </div>
        <div class="chip">
          <div class="chiplegend">Duration</div>
          <div class="chipbigb">{{ duration }}</div>
        </div>
        <div class="chip">
          <div class="chiplegend">Content Kind</div>
          <div class="chipbigb">{{ contentKind(cpl.ContentKind) }}</div>
        </div>
        <br />
        <div class="chip">
          <div class="chiplegend">Segments</div>
          <div class="chipbigb" v-if="cpl.SegmentList.length == 1">
            {{ cpl.SegmentList.length }}
          </div>
          <div class="chipbigy" v-if="cpl.SegmentList.length != 1">
            {{ cpl.SegmentList.length }}
          </div>
        </div>
        <div class="chip">
          <div class="chiplegend">Subtitles</div>
          <div class="chipbigb">{{ yesNo(cpl.subtitles) }}</div>
        </div>
        <div class="chip">
          <div class="chiplegend">Markers</div>
          <div class="chipbigb">
            {{ yesNo(markers_list.length > 0) }}
          </div>
        </div>
        <div class="chip" v-if="cpl.picture_descriptor == true">
          <div class="chiplegend">Dynamic Range</div>
          <div class="chipbigg" v-if="hdr == true">HDR</div>
          <div class="chipbigb" v-if="hdr == false">SDR</div>
        </div>
        <br />
        <div class="chip" v-if="cpl.definition != null">
          <div class="chiplegend">Definition</div>
          <div class="chipbigb">{{ cpl.definition }}</div>
        </div>
        <div class="chip" v-if="aspect_ratio != null">
          <div class="chiplegend">Aspect Ratio</div>
          <div class="chipbigb">{{ aspect_ratio }}</div>
        </div>
        <br />
      </center>
      <template v-if="hasKey(cpl, 'EssenceDescriptorList')">
        <a
          v-if="essenceDescriptorModal"
          @click="essenceDescriptorModal = false"
        >
          <h3>
            Essence Descriptors <small><i class="bi bi-file-minus"></i></small>
          </h3>
        </a>
        <a v-else @click="essenceDescriptorModal = true">
          <h3>
            Essence Descriptors <small><i class="bi bi-file-plus"></i></small>
          </h3>
        </a>
        <template v-if="essenceDescriptorModal">
          <PrettyPrint
            v-bind:data="cpl.EssenceDescriptorList"
            v-bind:indentation="0"
          ></PrettyPrint>
        </template>
      </template>
      <template v-if="hasKey(cpl, 'LocaleList')">
        <a v-if="localListModal" @click="localListModal = false">
          <h3>
            Locale List <small><i class="bi bi-file-minus"></i></small>
          </h3>
        </a>
        <a v-else @click="localListModal = true">
          <h3>
            Locale List <small><i class="bi bi-file-plus"></i></small>
          </h3>
        </a>
        <template v-if="localListModal">
          <PrettyPrint
            v-bind:data="cpl.LocaleList"
            v-bind:indentation="0"
          ></PrettyPrint>
        </template>
      </template>
      <MarkersInfo
        v-if="markers_list.length > 0"
        v-bind:marker_list="markers_list"
      ></MarkersInfo>
      <hr />
      <h3>Segments List</h3>
      <TimeLine
        v-bind:SegmentList="cpl.SegmentList"
        v-bind:EssenceDescriptorList="cpl.EssenceDescriptorList"
      ></TimeLine>
    </div>
  </div>
</template>

<script>
/* FishTank 2023 */
/* IMP CPL parsing component */
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../../firebase/index";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const firbaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firbaseApp);
const firbaseDatabase = getDatabase(firbaseApp);

import config from "../tools/config.json";
import {
  contentkind,
  get_editrate,
  get_timecode,
  framerate,
  aspectratio,
  clean_object,
  get_value,
  has_key,
  to_array,
  yesno,
  parseNumber,
} from "../tools/utils";
import { format_imp_pdf } from "../tools/pdf";
import { ImpApplications } from "./imp/imp-applications";

export default {
  name: "ImpCpl",
  props: ["mainindex"],
  data() {
    return {
      iscpl: false,
      imfModal: false,
      imf_application: null,
      applicationModal: false,
      essenceDescriptorModal: false,
      localListModal: false,
      applicationDescription: ImpApplications,
      image_complete_intrinsic_duration: 0,
      image_editrate: 0,
      audio_editrate: 0,
      subtile_editrate: 0,
      hdr: false,
      uhd: null,
      aspect_ratio: null,
      definition: null,
      duration: "",
      markers_list: [],
      cpl: {},
    };
  },
  created: function () {
    if (!this.$store.state.files[this.mainindex].check) this.parseCpl();
  },
  methods: {
    frameRate: function (value) {
      return framerate(value);
    },
    hasKey: function (x, y) {
      return has_key(x, y);
    },
    yesNo: function (value) {
      return yesno(value);
    },
    getValue: function (value) {
      return get_value(value);
    },
    contentKind: function (value) {
      return get_value(contentkind(value));
    },
    parseCpl() {
      if (
        Object.keys(this.$store.state.files[this.mainindex].data) ==
          "CompositionPlaylist" &&
        this.$store.state.files[this.mainindex].check == false
      ) {
        this.cpl =
          this.$store.state.files[this.mainindex].data.CompositionPlaylist;
        this.xmlnsTest();
      }

      if (this.iscpl) {
        this.cpl.fileName = this.$store.state.files[this.mainindex].name;
        if (has_key(this.cpl, "ContentTitle")) {
          this.cpl.ContentTitle = get_value(this.cpl.ContentTitle);
        }
        this.getApplication();
        this.segmentListParser();
        this.essenceDescpriptorParse();
        this.duration = get_timecode(
          this.image_complete_intrinsic_duration,
          this.image_editrate
        );

        setTimeout(() => {
          this.updateCplBigData();
        }, (Math.floor(Math.random() * 40) + 1) * 100 + 1000);

        this.$store.state.files[this.mainindex].check = true;
        this.$store.state.files[this.mainindex].label = get_value(
          this.cpl.ContentTitle
        );
        this.$store.state.files[this.mainindex].package = "IMP";
        this.$store.state.files[this.mainindex].type = "CPL";
        this.$store.state.files[this.mainindex].uuid = get_value(this.cpl.Id);
        this.$store.state.files[this.mainindex].info = {
          edit_rate: this.frameRate(this.getValue(this.cpl.EditRate)),
          duration: this.duration,
          content_kind: this.contentKind(this.cpl.ContentKind),
          segments: this.cpl.SegmentList.length,
        };
      }
    },
    updateCplBigData() {
      let data = {};
      const currentUserData = ref(
        firbaseDatabase,
        `users/${firebaseAuth.currentUser.uid}`
      );
      onValue(
        currentUserData,
        (snapshot) => {
          if (snapshot.exists) {
            data = snapshot.val();
            let er = `_${String(this.image_editrate).replace(".", "_")}`;
            if (has_key(data.imp.edit_rate, er)) {
              data.imp.edit_rate[er] += 1;
            } else {
              data.imp.edit_rate[er] = 1;
            }

            let ap = `_${this.imf_application.num}`;
            if (has_key(data.imp.application, ap)) {
              data.imp.application[ap] += 1;
            } else {
              data.imp.application[ap] = 1;
            }
            if (this.hdr) {
              data.imp.hdr += 1;
            }
            if (this.aspect_ratio != null) {
              let ar = `_${String(aspectratio(this.aspect_ratio))
                .replace(".", "_")
                .replace(":", "_")}`;
              if (has_key(data.imp.aspect_ratio, ar)) {
                data.imp.aspect_ratio[ar] += 1;
              } else {
                data.imp.aspect_ratio[ar] = 1;
              }
            }
            data.imp.total += 1;
            data.use += 1;
            set(currentUserData, data);
          }
        },
        {
          onlyOnce: true,
        }
      );
    },
    xmlnsTest() {
      if (this.cpl.$.xmlns.indexOf(config.xmlns.imp_cpl) > -1) {
        this.iscpl = true;
      }
    },
    pdfExport() {
      let docDefinition = format_imp_pdf(
        this.cpl,
        this.imf_application.num,
        this.markers_list,
        this.duration,
        this.aspect_ratio
      );
      pdfMake
        .createPdf(docDefinition)
        .download(
          `cpl_translator_${this.cpl.ContentTitle.replaceAll(
            " ",
            "_"
          ).toLowerCase()}.pdf`
        );
    },
    resourceParser(resource, edit_rate) {
      if (has_key(resource, "SourceEncoding")) {
        let obj = {};
        obj[resource.SourceEncoding] = false;
        Object.assign(this.descriptorModal, obj);
      }
      resource.EntryPoint = parseNumber(resource.EntryPoint);
      resource.SourceDuration = parseNumber(resource.SourceDuration);
      resource.IntrinsicDuration = parseNumber(resource.IntrinsicDuration);
      resource.EntryPoint_tc = get_timecode(resource.EntryPoint, edit_rate);
      resource.SourceDuration_tc = get_timecode(
        resource.SourceDuration,
        edit_rate
      );
      resource.IntrinsicDuration_tc = get_timecode(
        resource.IntrinsicDuration,
        edit_rate
      );
      return resource;
    },
    setResourceList(resourceList) {
      resourceList = to_array(resourceList);
      let list = [];
      resourceList.forEach((r) => {
        list = list.concat(r.Resource);
      });
      return list;
    },
    segmentListParser() {
      this.descriptorModal = {};
      this.cpl.SegmentList.forEach((segmentList) => {
        segmentList.Segment.forEach((segment) => {
          segment.SequenceList.forEach((sequence) => {
            Object.keys(sequence).forEach((key) => {
              if (key.includes(":")) {
                sequence[key.split(":")[1]] = sequence[key];
                delete sequence[key];
              }
            });
          });
          segment.SequenceList.forEach((sequence) => {
            if (has_key(sequence, "MainImageSequence")) {
              sequence.duration = 0;
              sequence.MainImageSequence = to_array(sequence.MainImageSequence);
              sequence.MainImageSequence.forEach((mainImageSequence) => {
                let image_duration = 0;
                let image_segment_list = [];
                mainImageSequence.ResourceList = this.setResourceList(
                  mainImageSequence.ResourceList
                );
                mainImageSequence.ResourceList.forEach((resource) => {
                  if (
                    has_key(resource, "EditRate") &&
                    this.image_editrate == 0
                  ) {
                    this.image_editrate = get_editrate(
                      get_value(resource.EditRate)
                    );
                  } else {
                    resource.EditRate = this.image_editrate;
                  }
                  resource = this.resourceParser(resource, this.image_editrate);
                  sequence.duration += resource.SourceDuration;
                  image_segment_list.push(parseNumber(resource.SourceDuration));

                  this.image_complete_intrinsic_duration += parseNumber(
                    resource.SourceDuration
                  );
                  resource.SourceDuration_sec =
                    resource.SourceDuration / this.image_editrate;
                  resource.in_complete_tc = get_timecode(
                    image_duration,
                    this.image_editrate
                  );
                  resource.out_complete_tc = get_timecode(
                    resource.SourceDuration + image_duration - 1,
                    this.image_editrate
                  );
                  image_duration += resource.SourceDuration;
                });
                sequence.duration_tc = get_timecode(
                  sequence.duration,
                  this.image_editrate
                );
              });
            }
            if (has_key(sequence, "MainAudioSequence")) {
              sequence.MainAudioSequence = to_array(sequence.MainAudioSequence);
              sequence.MainAudioSequence.forEach((mainAudioSequence) => {
                let audio_duration = 0;
                mainAudioSequence.ResourceList = this.setResourceList(
                  mainAudioSequence.ResourceList
                );
                mainAudioSequence.ResourceList.forEach((resource) => {
                  if (
                    has_key(resource, "EditRate") &&
                    this.audio_editrate == 0
                  ) {
                    this.audio_editrate = get_editrate(
                      get_value(resource.EditRate)
                    );
                  }
                  resource = this.resourceParser(resource, this.audio_editrate);
                  sequence.duration += resource.SourceDuration;
                  resource.SourceDuration_sec =
                    resource.SourceDuration / this.audio_editrate;
                  resource.in_complete_tc = get_timecode(
                    audio_duration,
                    this.audio_editrate
                  );
                  resource.out_complete_tc = get_timecode(
                    resource.SourceDuration + audio_duration - 1,
                    this.audio_editrate
                  );
                  audio_duration += resource.SourceDuration;
                });
              });
            }
            if (has_key(sequence, "SubtitlesSequence")) {
              sequence.SubtitlesSequence = to_array(sequence.SubtitlesSequence);
              sequence.SubtitlesSequence.forEach((subtitlesSequence) => {
                subtitlesSequence.ResourceList = this.setResourceList(
                  subtitlesSequence.ResourceList
                );
                subtitlesSequence.ResourceList.forEach((resource) => {
                  if (
                    has_key(resource, "EditRate") &&
                    this.subtile_editrate == 0
                  ) {
                    this.subtile_editrate = get_editrate(
                      get_value(resource.EditRate)
                    );
                  } else {
                    this.subtile_editrate = this.image_editrate;
                    resource.EditRate = this.image_editrate;
                  }
                  resource = this.resourceParser(
                    resource,
                    this.subtile_editrate
                  );
                });
              });
            }
            if (has_key(sequence, "MarkerSequence")) {
              sequence.MarkerSequence = to_array(sequence.MarkerSequence);
              sequence.MarkerSequence.forEach((MarkerSequence) => {
                MarkerSequence.ResourceList = this.setResourceList(
                  MarkerSequence.ResourceList
                );
                MarkerSequence.ResourceList.forEach((resource) => {
                  resource = this.resourceParser(resource, this.image_editrate);
                  if (has_key(resource, "Marker")) {
                    resource.Marker = to_array(resource.Marker);
                    resource.Marker.forEach((marker) => {
                      marker.segment = get_value(segment.Id);
                      marker.tc = get_timecode(
                        marker.Offset,
                        this.image_editrate
                      );
                      this.markers_list.push(marker);
                    });
                  }
                });
              });
            }
          });
        });
      });
    },
    getApplication() {
      if (has_key(this.cpl, "ExtensionProperties")) {
        this.cpl.ExtensionProperties.forEach((extensionProperty, i) => {
          this.cpl.ExtensionProperties[i] = clean_object(extensionProperty);
          extensionProperty["ApplicationIdentification"].forEach(
            (applicationIdentification) => {
              this.imf_application = ImpApplications.filter(
                (ImfApp) => ImfApp.xmlns == applicationIdentification._
              )[0];
              if (!has_key(this.imf_application, "num")) {
                this.imf_application = {
                  num: "unknown",
                  xmlns: applicationIdentification._,
                };
              }
            }
          );
        });
      }
    },
    essenceDescpriptorParse() {
      this.cpl.picture_descriptor = false;
      if (has_key(this.cpl, "EssenceDescriptorList")) {
        this.cpl.EssenceDescriptorList.EssenceDescriptor = to_array(
          this.cpl.EssenceDescriptorList.EssenceDescriptor
        );
        this.cpl.EssenceDescriptorList.EssenceDescriptor.forEach(
          (essenceDescriptor) => {
            if (has_key(essenceDescriptor, "CDCIDescriptor")) {
              this.cpl.picture_descriptor = true;
              this.aspect_ratio =
                essenceDescriptor.CDCIDescriptor.ImageAspectRatio;
              this.cpl.definition = `${essenceDescriptor.CDCIDescriptor.DisplayWidth}x${essenceDescriptor.CDCIDescriptor.DisplayHeight}`;
              this.uhd = false;
              if (
                essenceDescriptor.CDCIDescriptor.ColorRange == "897" &&
                essenceDescriptor.CDCIDescriptor.WhiteRefLevel == "940" &&
                essenceDescriptor.CDCIDescriptor.BlackRefLevel == "64" &&
                essenceDescriptor.CDCIDescriptor.ComponentDepth == "10"
              ) {
                this.uhd = true;
              }
              if (
                essenceDescriptor.CDCIDescriptor.CodingEquations ==
                  "urn:smpte:ul:060e2b34.0401010d.04010101.02060000" &&
                essenceDescriptor.CDCIDescriptor.ColorPrimaries ==
                  "urn:smpte:ul:060e2b34.0401010d.04010101.03040000"
              ) {
                this.hdr = true;
              }
            }
            if (has_key(essenceDescriptor, "RGBADescriptor")) {
              this.aspect_ratio =
                essenceDescriptor.RGBADescriptor.ImageAspectRatio;
              this.cpl.definition = `${essenceDescriptor.RGBADescriptor.DisplayWidth}x${essenceDescriptor.RGBADescriptor.DisplayHeight}`;
            }
          }
        );
      }
    },
  },
};
</script>
