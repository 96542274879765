<template lang="html">
  <div>
    <div v-if="isObject(data)" v-bind:style="classObject(0)">
      <div v-for="(value, key) in data" :key="key">
        <b>{{ cleanKey(key) }}</b>
        <span v-if="isString(value)">{{ value }}</span>
        <span v-else-if="isElse(value)" v-bind:class="classObject(10)">
          {{ getValue(value) }}</span
        >
        <span v-else-if="isArray(value)">
          <div
            v-for="(sub_value, sub_key) in value"
            :key="sub_key"
            v-bind:class="classObject(10)"
          >
            <span v-if="isElse(sub_value)">- {{ getValue(sub_value) }} </span>
            <PrettyPrint
              v-if="isObject(sub_value)"
              v-bind:data="sub_value"
              v-bind:indentation="indent + 10"
            ></PrettyPrint>
          </div>
        </span>
        <PrettyPrint
          v-if="isObject(value)"
          v-bind:data="value"
          v-bind:indentation="indent"
        ></PrettyPrint>
      </div>
    </div>
    <div v-if="isArray(data)">
      <div
        v-for="(sub_value, sub_key) in data"
        :key="sub_key"
        v-bind:class="classObject(10)"
      >
        <span v-if="isElse(sub_value)">- {{ getValue(sub_value) }} </span>
        <PrettyPrint
          v-if="isObject(sub_value)"
          v-bind:data="sub_value"
          v-bind:indentation="indent + 10"
        ></PrettyPrint>
      </div>
    </div>
  </div>
</template>

<script>
/* FishTank 2023 */
/* Auto Pretty Print Object */
import { clean_key, get_value } from "./utils";

export default {
  name: "PrettyPrint",
  props: ["data", "indentation"],
  components: {
    PrettyPrint: require("./PrettyPrint.vue"),
  },
  data() {
    return {
      indent: 0,
    };
  },
  created: function () {
    this.indent = this.indentation + 5;
  },
  methods: {
    classObject: function (n) {
      return {
        "text-indent": `${this.indent + n}px`,
      };
    },
    isString: function (x) {
      if (x == "r0" || x == "r1" || x == "r2") {
        return false;
      }
      return (
        String(x) != "[object Object]" &&
        !String(x).startsWith("function") &&
        !Array.isArray(x)
      );
    },
    isArray: function (x) {
      return x instanceof String;
    },
    isObject: function (x) {
      return x instanceof Object;
    },
    getValue: function (x) {
      return get_value(x);
    },
    cleanKey: function (x) {
      return clean_key(x);
    },
    isElse: function (x) {
      return !this.isObject(x) && !this.isArray(x) && !this.isString(x);
    },
  },
};
</script>
