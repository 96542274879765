const firebaseConfig = {
  apiKey: "AIzaSyBjg-CJWB9AO57eXSuLfgPMbkQE_uodsz8",
  authDomain: "cpltranslator.firebaseapp.com",
  databaseURL: "https://cpltranslator.firebaseio.com",
  projectId: "cpltranslator",
  storageBucket: "cpltranslator.appspot.com",
  messagingSenderId: "298556366175",
};

export { firebaseConfig };
