<template lang="html">
  <div>
    <h3>Markers</h3>
    <table class="table table-sm">
      <tbody>
        <tr v-for="(marker, id) in marker_list" :key="id">
          <td>
            <span v-if="marker.segment"
              >Segment : {{ marker.segment + 1 }}<br
            /></span>
            Offset : {{ getValue(marker.Offset) }} [{{ marker.tc }}]
          </td>
          <td>
            {{ getValue(marker.Label) }} {{ getValue(marker.Annotation) }}<br />
            {{ Legend[marker.Label] }}<br />
            {{ marker.Label._scope }}<br />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* FishTank 2023 */
import { get_value } from "../tools/utils";

export default {
  name: "MarkersInfo",
  props: ["marker_list"],
  methods: {
    getValue: function (value) {
      return get_value(value);
    },
  },
  data() {
    return {
      Legend: {
        FFBT: "First Frame of Bars and Tone",
        LFBT: "Last Frame of Bars and Tone",
        FFCB: "First Frame of Commercial Blacks",
        LFCB: "Last Frame of Commercial Blacks",
        FFCL: "First Frame of Company/Production Logo",
        LFCL: "Last Frame of Company/Production Logo",
        FFDL: "First Frame of Distribution Logo",
        LFDL: "Last Frame of Distribution Logo",
        FFEC: "First Frame of End Credits. First displayable frame of content that contains any intensity of the End Credits (a non zero alpha value),which appear at the end of a feature.",
        LFEC: "Last Frame of End Credits. Last displayable frame of content that contains any intensity of the End Credits (a non zero alpha value), which appear at the end of a feature.",
        FFHS: "First Frame of Head Slate",
        LFHS: "Last Frame of Head Slate",
        FFMC: "First displayable frame of content that contains any intensity of moving, rolling or scrolling credits (a non-zero alpha value), which appear at the end of the feature.",
        LFMC: "Last displayable frame of content that contains any intensity of moving, rolling or scrolling credits (a non-zero alpha value), which appear at the end of the feature.",
        FFOB: "First Frame of Ratings Band. First displayable frame of content of the Rating Band, which is usually a slate at the beginning of a feature.",
        LFOB: "Last Frame of Ratings Band. Last displayable frame of content of the Rating Band, which is usually a slate at the beginning of a feature.",
        LFOC: "Last Frame of Composition. The last frame of a composition that is intended for display.",
        FFOC: "First Frame of Composition. The first frame of a composition that is intended for display.",
        FFOI: "First Frame of Intermission",
        LFOI: "Last Frame of Intermission",
        FFSP: "First Frame of Digital Sync Pop",
        LFSP: "Last Frame of Digital Sync Pop",
        FFTC: "First Frame of Title Credits. First displayable frame of content that contains any intensity of the Title Credits (a non zero alpha value), which appear at the beginning of a feature.",
        LFTC: "Last Frame of Title Credits. Last displayable frame of content that contains any intensity of the Title Credits (a non zero alpha value), which appear at the beginning of a feature.",
        FFTS: "First Frame of Tail Slate",
        LFTS: "Last Frame of Tail Slate",
        FTXC: "First Frame of Textless Title Credits",
        LTXC: "Last frame of Textless Title Credits",
        FTXE: "First Frame of Textless End Credits",
        LTXE: "Last Frame of Textless End Credits",
        FTXM: "First Frame of Textless Material Segment",
        LTXM: "Last frame of Textless Material Segment",
        FFOA: "Audio First Frame. First frame of audio ring-in/ring-out where the video is in black.",
        LFOA: "Audio Last Frame. Last frame of audio ring-in/ring-out where the video is in black.",
        FPCI: "Fixed Point Candidate Insertion. Indicates possible point in the timeline where it would be allowable to insert content downstrean. This is for material that may not have commercial blacks, but could indicate a candidate point where a commercial could be inserted.",
        FFCO: "First Frame of Candidate Overlay. First frame of a sequence of frames where overlays, e.g. commercial overlays, may be placed.",
        LFCO: "Last Frame of Candidate Overlay. Last frame of a sequence of frames where overlays, e.g. commercial overlays, may be placed.",
        FFDC: "First Frame of Dubbing Credits. First displayable frame of content that contains any intensity of dubbing credits",
        LFDC: "Last Frame of Dubbing credits: Last displayable frame of content that contains any intensity of dubbing credits",
      },
    };
  },
};
</script>
