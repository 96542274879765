var ImpApplications = [
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-2/2013",
    url: "https://ieeexplore.ieee.org/document/7291584",
    num: "1",
    description:
      "ST 2067-2:2013 - SMPTE Standard - Interoperable Master Format — Core Constraints #1",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-2/2016",
    url: "https://ieeexplore.ieee.org/document/7560857",
    num: "1",
    description:
      "ST 2067-2:2016 - SMPTE Standard - Interoperable Master Format — Core Constraints #1",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-20/2013",
    url: "https://ieeexplore.ieee.org/document/7292004",
    num: "2",
    description:
      "ST 2067-20:2013 - SMPTE Standard - Interoperable Master Format — Application #2",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-20/2016",
    url: "https://ieeexplore.ieee.org/document/7560836",
    num: "2",
    description:
      "ST 2067-20:2016 - SMPTE Standard - Interoperable Master Format — Application #2",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-21/2014",
    url: "https://ieeexplore.ieee.org/document/7290345",
    num: "2E",
    description:
      "ST 2067-21:2014 - SMPTE Standard - Interoperable Master Format — Application #2E",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-21/2016",
    url: "https://ieeexplore.ieee.org/document/7560818",
    num: "2E",
    description:
      "ST 2067-21:2016 - SMPTE Standard - Interoperable Master Format — Application #2E",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-30/2013",
    url: "https://ieeexplore.ieee.org/document/7291032",
    num: "3",
    description:
      "ST 2067-30:2013 - SMPTE Standard - Interoperable Master Format — Application #3",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-40/2016",
    url: "https://ieeexplore.ieee.org/document/7565459",
    num: "4",
    description:
      "ST 2067-40:2016 - SMPTE Standard - Interoperable Master Format — Application #4 Cinema Mezzanine",
  },
  {
    xmlns: "http://www.smpte-ra.org/schemas/2067-50/2018",
    url: "https://ieeexplore.ieee.org/document/8320049",
    num: "5",
    description:
      "ST 2067-50:2018 - SMPTE Standard - Interoperable Master Format — Application #5 ACES",
  },
  {
    xmlns: "",
    url: "https://ieeexplore.ieee.org/document/8233487",
    num: "ProRes",
    description:
      "RDD45:2017 - RDD45 - Interoperable Master Format — Application ProRes",
  },
  {
    xmlns:
      "http://www.digitalproductionpartnership.co.uk/schema/imf/TSP2121-1/2018",
    url: "http://www.smpte.org/technical-specifications/2018-08-16-app-dpp",
    num: "DPP",
    description:
      "TSP 2121-1:2018 - RDD45 - Interoperable Master Format — Application DPP",
  },
];

export { ImpApplications };
