<!-- FishTank 2023-->
<template lang="html">
  <div class="card" style="width: 70%">
    <div class="card-body">
      <div class="wordmark_cpl_logo"></div>
      <p style="text-align: left; padding-left: 50px">
        CPL Translator is a simple yet powerful Drag-and-Drop web application to
        parse :
      </p>
      <ul style="text-align: left; padding-left: 75px">
        <li><strong>Digital Cinema Package (DCP) :</strong></li>
        <li style="list-style-type: none">
          <ul style="text-align: left">
            <li><strong>Composition Playlist (CPL)</strong></li>
            <li><strong>Packing List (PKL)</strong></li>
          </ul>
        </li>
        <li>
          <strong>Interoperable Master Format Package (IMP) :</strong>
        </li>
        <li style="list-style-type: none">
          <ul style="text-align: left">
            <li><strong>Composition Playlist (CPL)</strong></li>
            <li><strong>Packing List (PKL)</strong></li>
            <li><strong>Output Profile List (OPL)</strong></li>
          </ul>
        </li>
      </ul>
      <ul style="text-align: left; padding-left: 50px">
        <li>
          It graphically represents the CPL metadata that’s visually easy to
          read and identify.
        </li>
        <li>
          It checks a DCP or IMP Composition and application type and reports
          main metadata details.
        </li>
        <li>
          It parses the PKL and determines the transmission times of each asset
          based on the method used.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainDescription",
};
</script>
