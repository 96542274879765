<script setup>
import CDCIDescriptor from "./CDCIDescriptor.vue";
import WAVEPCMDescriptor from "./WAVEPCMDescriptor.vue";
import RGBADescriptor from "./RGBADescriptor.vue";
import DCTimedTextDescriptor from "./DCTimedTextDescriptor.vue";
</script>

<template lang="html">
  <div
    class="modal fade"
    v-bind:id="`CPLTimelineModal${id}`"
    tabindex="-1"
    v-bind:aria-labelledby="`CPLTimelineModal${id}`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Segment Information</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-for="(segmentList, index) in SegmentList" :key="index">
            <template
              v-for="(segment, index) in segmentList.Segment"
              :key="index"
            >
              <div v-if="currentSegment == getValue(segment.Id)">
                <h4>
                  Segment {{ index + 1 }}
                  <small>{{ getValue(segment.Annotation) }}</small>
                </h4>
                ID : {{ getValue(segment.Id) }}<br />
                <template
                  v-for="(sequence, index) in segment.SequenceList"
                  :key="index"
                >
                  <template v-if="hasKey(sequence, 'MainImageSequence')">
                    <div
                      class="block"
                      v-for="(
                        mainImageSequence, image_index
                      ) in sequence.MainImageSequence"
                      :key="image_index"
                      v-bind:id="getValue(mainImageSequence.Id)"
                    >
                      <h5>Image Sequence {{ image_index + 1 }}</h5>
                      ID: {{ getValue(mainImageSequence.Id) }}<br />Track ID:
                      {{ getValue(mainImageSequence.TrackId) }}<br />
                      <div
                        v-for="(
                          resource, resource_index
                        ) in mainImageSequence.ResourceList"
                        :key="resource_index"
                        class="block"
                      >
                        <div class="image-info" v-bind:id="resource.Id">
                          <span class="asset_id"
                            ><b
                              >Image Resource {{ resource_index + 1 }}
                              {{ getValue(resource.Annotation) }}</b
                            ></span
                          >
                          <span class="asset_uuid">{{
                            getValue(resource.Id)
                          }}</span>
                          <span class="asset_data">
                            Edit Rate :
                            {{ frameRate(getValue(resource.EditRate)) }}<br />
                            Entry Point : {{ resource.EntryPoint_tc }} [{{
                              resource.EntryPoint
                            }}
                            Frames]<br />
                            In : {{ resource.in_complete_tc }} - Out :
                            {{ resource.out_complete_tc }}
                          </span>
                          <span class="asset_duration">
                            Duration : {{ resource.SourceDuration_tc }} [{{
                              resource.SourceDuration
                            }}
                            Frames]<br />
                            Intrinsic Duration :
                            {{ resource.IntrinsicDuration_tc }} [{{
                              resource.IntrinsicDuration
                            }}
                            Frames]<br />
                          </span>
                        </div>
                        <div v-if="hasKey(resource, 'SourceEncoding')">
                          <CDCIDescriptor
                            v-bind:descriptors="EssenceDescriptorList"
                            v-bind:sourceencoding="resource.SourceEncoding"
                          ></CDCIDescriptor>
                          <RGBADescriptor
                            v-bind:descriptors="EssenceDescriptorList"
                            v-bind:sourceencoding="resource.SourceEncoding"
                          ></RGBADescriptor>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </template>
                  <template v-if="hasKey(sequence, 'MainAudioSequence')">
                    <div
                      class="block"
                      v-for="(
                        mainAudioSequence, audio_index
                      ) in sequence.MainAudioSequence"
                      :key="audio_index"
                      v-bind:id="getValue(mainAudioSequence.Id)"
                    >
                      <h5>Audio Sequence {{ audio_index + 1 }}</h5>
                      ID: {{ getValue(mainAudioSequence.Id) }}<br />Track ID:
                      {{ getValue(mainAudioSequence.TrackId) }}<br />
                      <div
                        v-for="(
                          resource, resource_index
                        ) in mainAudioSequence.ResourceList"
                        :key="resource_index"
                        class="block"
                      >
                        <div class="audio-info" v-bind:id="resource.Id">
                          <span class="asset_id"
                            ><b
                              >Audio Resource {{ resource_index + 1 }}
                              {{ getValue(resource.Annotation) }}</b
                            ></span
                          >
                          <span class="asset_uuid">{{
                            getValue(resource.Id)
                          }}</span>
                          <span class="asset_data">
                            Edit Rate :
                            {{ frameRate(getValue(resource.EditRate)) }}<br />
                            Entry Point : {{ resource.EntryPoint_tc }} [{{
                              resource.EntryPoint
                            }}
                            Frames]<br />
                            In : {{ resource.in_complete_tc }} - Out :
                            {{ resource.out_complete_tc }}
                          </span>
                          <span class="asset_duration">
                            Duration : {{ resource.SourceDuration_tc }} [{{
                              resource.SourceDuration
                            }}
                            Frames]<br />
                            Intrinsic Duration :
                            {{ resource.IntrinsicDuration_tc }} [{{
                              resource.IntrinsicDuration
                            }}
                            Frames]<br />
                          </span>
                        </div>
                        <div v-if="hasKey(resource, 'SourceEncoding')">
                          <WAVEPCMDescriptor
                            v-bind:descriptors="EssenceDescriptorList"
                            v-bind:sourceencoding="resource.SourceEncoding"
                          ></WAVEPCMDescriptor>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </template>
                  <template v-if="hasKey(sequence, 'SubtitlesSequence')">
                    <div
                      class="block"
                      v-for="(
                        subtitleSequence, subtitle_index
                      ) in sequence.SubtitlesSequence"
                      :key="subtitle_index"
                      v-bind:id="getValue(subtitleSequence.Id)"
                    >
                      <h5>Subtitle Sequence {{ subtitle_index + 1 }}</h5>
                      ID: {{ getValue(subtitleSequence.Id) }}<br />Track ID:
                      {{ getValue(subtitleSequence.TrackId) }}<br />
                      <div
                        v-for="(
                          resource, resource_index
                        ) in subtitleSequence.ResourceList"
                        :key="resource_index"
                        class="block"
                      >
                        <div class="subtitle-info" v-bind:id="resource.Id">
                          <span class="asset_id"
                            ><b
                              >Subtitle Resource {{ resource_index + 1 }}
                              {{ getValue(resource.Annotation) }}</b
                            ></span
                          >
                          <span class="asset_uuid">{{
                            getValue(resource.Id)
                          }}</span>
                          <span class="asset_data">
                            Edit Rate :
                            {{ frameRate(getValue(resource.EditRate)) }}<br />
                            Entry Point : {{ resource.EntryPoint_tc }} [{{
                              resource.EntryPoint
                            }}
                            Frames]<br />
                          </span>
                          <span class="asset_duration">
                            Intrinsic Duration :
                            {{ resource.IntrinsicDuration_tc }} [{{
                              resource.IntrinsicDuration
                            }}
                            Frames]<br />
                          </span>
                        </div>
                        <div v-if="hasKey(resource, 'SourceEncoding')">
                          <DCTimedTextDescriptor
                            v-bind:descriptors="EssenceDescriptorList"
                            v-bind:sourceencoding="resource.SourceEncoding"
                          ></DCTimedTextDescriptor>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </template>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div class="timeline">
    <table>
      <tr class="horizontal-segment-tr">
        <td class="horizontal-header" style="min-width: 100px"></td>
        <td class="vertical-separator"></td>
        <template v-for="(segmentList, index) in SegmentList" :key="index">
          <template
            v-for="(segment, index) in segmentList.Segment"
            :key="index"
          >
            <td class="horizontal-segment">
              <span
                @click="setCurrent(segment.Id)"
                data-bs-toggle="modal"
                v-bind:data-bs-target="`#CPLTimelineModal${id}`"
                >Segment {{ index + 1 }}
                <i class="bi bi-arrow-up-right-circle"></i> Details</span
              >
            </td>
            <td class="vertical-separator"></td>
          </template>
        </template>
      </tr>
    </table>
    <table>
      <tr class="horizontal-segment-tr">
        <td class="horizontal-header" style="min-width: 100px">Image</td>
        <td class="vertical-separator"></td>
        <template v-for="(segmentList, index) in SegmentList" :key="index">
          <template
            v-for="(segment, index) in segmentList.Segment"
            :key="index"
          >
            <td class="horizontal-segment">
              <template
                v-for="(sequence, index) in segment.SequenceList"
                :key="index"
              >
                <template v-if="hasKey(sequence, 'MainImageSequence')">
                  <div
                    class="horizontal-sequence"
                    v-for="(
                      mainImageSequence, image_sequence_index
                    ) in sequence.MainImageSequence"
                    :key="image_sequence_index"
                  >
                    <span
                      @click="setCurrent(segment.Id)"
                      data-bs-toggle="modal"
                      v-bind:data-bs-target="`#CPLTimelineModal${id}`"
                      >Sequence {{ image_sequence_index + 1 }}</span
                    >
                    <br />
                    {{ sequence.duration_tc }}
                    <div
                      v-for="(
                        resource, resource_index
                      ) in mainImageSequence.ResourceList"
                      :key="resource_index"
                      class="horizontal-image"
                    >
                      <span
                        @click="setCurrent(segment.Id)"
                        data-bs-toggle="modal"
                        v-bind:data-bs-target="`#CPLTimelineModal${id}`"
                        >Resource {{ resource_index + 1 }}<br />
                        {{ resource.SourceDuration_tc }}</span
                      >
                    </div>
                  </div>
                </template>
              </template>
            </td>
            <td class="vertical-separator"></td>
          </template>
        </template>
      </tr>
    </table>
    <table>
      <tr class="horizontal-segment-tr">
        <td class="horizontal-header" style="min-width: 100px">Audio</td>
        <td class="vertical-separator"></td>
        <template v-for="(segmentList, index) in SegmentList" :key="index">
          <template
            v-for="(segment, index) in segmentList.Segment"
            :key="index"
          >
            <td class="horizontal-segment">
              <template
                v-for="(sequence, index) in segment.SequenceList"
                :key="index"
              >
                <template v-if="hasKey(sequence, 'MainAudioSequence')">
                  <div
                    class="horizontal-sequence"
                    v-for="(
                      mainAudioSequence, audio_sequence_index
                    ) in sequence.MainAudioSequence"
                    :key="audio_sequence_index"
                  >
                    <span
                      @click="setCurrent(segment.Id)"
                      data-bs-toggle="modal"
                      v-bind:data-bs-target="`#CPLTimelineModal${id}`"
                      >Sequence {{ audio_sequence_index + 1 }}</span
                    >
                    <br />
                    {{ sequence.duration_tc }}
                    <div
                      v-for="(
                        resource, resource_index
                      ) in mainAudioSequence.ResourceList"
                      :key="resource_index"
                      class="horizontal-audio"
                    >
                      <span
                        @click="setCurrent(segment.Id)"
                        data-bs-toggle="modal"
                        v-bind:data-bs-target="`#CPLTimelineModal${id}`"
                        >Resource {{ resource_index + 1 }}<br />
                        {{ resource.SourceDuration_tc }}</span
                      >
                    </div>
                  </div>
                </template>
              </template>
            </td>
            <td class="vertical-separator"></td>
          </template>
        </template>
      </tr>
    </table>
    <table>
      <tr class="horizontal-segment-tr">
        <td class="horizontal-header" style="min-width: 100px">Subtitle</td>
        <td class="vertical-separator"></td>
        <template v-for="(segmentList, index) in SegmentList" :key="index">
          <template
            v-for="(segment, index) in segmentList.Segment"
            :key="index"
          >
            <td class="horizontal-segment">
              <template
                v-for="(sequence, index) in segment.SequenceList"
                :key="index"
              >
                <template v-if="hasKey(sequence, 'SubtitlesSequence')">
                  <div
                    class="horizontal-sequence"
                    v-for="(
                      subtitleSequence, subtitle_sequence_index
                    ) in sequence.SubtitlesSequence"
                    :key="subtitle_sequence_index"
                  >
                    <span
                      @click="setCurrent(segment.Id)"
                      data-bs-toggle="modal"
                      v-bind:data-bs-target="`#CPLTimelineModal${id}`"
                      >Sequence {{ subtitle_sequence_index + 1 }}</span
                    >
                    <br />
                    {{ sequence.duration_tc }}
                    <div
                      v-for="(
                        resource, resource_index
                      ) in subtitleSequence.ResourceList"
                      :key="resource_index"
                      class="horizontal-subtitle"
                    >
                      <span
                        @click="setCurrent(segment.Id)"
                        data-bs-toggle="modal"
                        v-bind:data-bs-target="`#CPLTimelineModal${id}`"
                        >Resource {{ resource_index + 1 }}<br />
                      </span>
                    </div>
                  </div>
                </template>
              </template>
            </td>
            <td class="vertical-separator"></td>
          </template>
        </template>
      </tr>
    </table>
  </div>
</template>

<script>
/* FishTank 2023 */
/* CPL IMP Timeline */
import { framerate, get_value, has_key, yesno } from "../../tools/utils";

export default {
  name: "TimeLine",
  props: ["SegmentList", "EssenceDescriptorList"],
  data() {
    return {
      id: null,
      sequences: [],
      currentSegment: null,
    };
  },
  created: function () {
    this.id = `${Math.floor(Math.random() * 100)}-${Math.floor(
      Math.random() * 100
    )}-${Math.floor(Math.random() * 100)}`;
  },
  methods: {
    setCurrent(id) {
      this.currentSegment = get_value(id);
    },
    frameRate: function (value) {
      return framerate(value);
    },
    hasKey: function (x, y) {
      return has_key(x, y);
    },
    yesNo: function (value) {
      return yesno(value);
    },
    getValue: function (value) {
      return get_value(value);
    },
  },
};
</script>
