import { createRouter, createWebHistory } from "vue-router";
import FileParser from "./components/FileParser.vue";
import UserInfo from "./components/UserInfo.vue";

const routes = [
  {
    path: "/files",
    name: "files",
    component: FileParser,
  },
  {
    path: "/user",
    name: "user",
    component: UserInfo,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
