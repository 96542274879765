<script setup>
import MarkersInfo from "./MarkersInfo.vue";
import SimplePrettyPrint from "../tools/SimplePrettyPrint.vue";
import TimeLine from "./dcp/TimeLine.vue";
</script>

<template lang="html">
  <div
    class="side"
    v-if="iscpl"
    :key="getValue(cpl.Id)"
    v-bind:id="getValue(cpl.Id)"
  >
    <h3 v-if="CompositionModal == false" @click="CompositionModal = true">
      CPL - Main Information
      <small><i class="bi bi-file-plus"></i></small>
    </h3>
    <h3
      v-if="CompositionModal == true"
      data-dismiss="modal"
      @click="CompositionModal = false"
    >
      CPL - Main Information
      <small><i class="bi bi-file-minus"></i></small>
    </h3>
    <div v-if="CompositionModal">
      <p>What is Composition Playlist (CPL)?</p>
      <p>
        A Composition Playlist is an XML document that consists of metadata
        pertaining to Reels which are comprised of ordered Image, Audio and
        Timed Text Track file resources. When ordered together, these Reels
        compose a motion picture composition of varying composition kind. The
        CPL controls the order and timing of playout of said Reels.
      </p>
    </div>
    <button class="btn btn-outline-secondary" @click="pdfExport">
      PDF Export
    </button>
    <br />
    <br />
    <div v-if="cpl.content_type == 'SMPTE'" class="smpte_logo"></div>
    <div v-if="cpl.content_type == 'IOP'" class="interop_logo"></div>
    <br />
    <table class="table table-sm">
      <tbody>
        <tr>
          <td>Content Title</td>
          <td>{{ getValue(cpl.ContentTitleText) }}</td>
        </tr>
        <tr>
          <td>Annotation Text</td>
          <td>{{ getValue(cpl.AnnotationText) }}</td>
        </tr>
        <tr>
          <td>File Name</td>
          <td>{{ cpl.fileName }}</td>
        </tr>
        <tr>
          <td>UUID</td>
          <td>{{ getValue(cpl.Id) }}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>Details</h3>
    <center>
      <div class="chip">
        <div class="chiplegend">Edit Rate</div>
        <div class="chipbigb" v-if="cpl.edit_rate <= 24">
          {{ cpl.edit_rate }} FPS
        </div>
        <div class="chipbigy" v-if="cpl.edit_rate > 24 && cpl.hfr == false">
          {{ cpl.edit_rate }} FPS
        </div>
        <div class="chipbigy" v-if="cpl.edit_rate > 24 && cpl.hfr == true">
          {{ cpl.edit_rate }} FPS HFR
        </div>
      </div>
      <div class="chip" v-if="hasKey(cpl, 'ReelList')">
        <div class="chiplegend">Nb of Reels</div>
        <div class="chipbigb">{{ cpl.ReelList.length }}</div>
      </div>
      <div class="chip">
        <div class="chiplegend">Aspect Ratio</div>
        <div class="chipbigb">{{ getValue(cpl.aspect_ratio.d) }}</div>
      </div>
      <div class="chip">
        <div class="chiplegend">Duration</div>
        <div class="chipbigb">{{ cpl.duration.tc }}</div>
      </div>
      <div class="chip">
        <div class="chiplegend">Content Kind</div>
        <div class="chipbigb">
          {{ contentKind(cpl.ContentKind) }}
        </div>
      </div>
      <br />
      <div class="chip">
        <div class="chiplegend">Picture Format</div>
        <div class="chipbigb">{{ pictureFormat(cpl.stereoscopic) }}</div>
      </div>
      <div class="chip">
        <div class="chiplegend">DCP Type</div>
        <div class="chipbigb">{{ cpl.content_type }}</div>
      </div>

      <div class="chip" v-if="cpl.time_text_subtitle">
        <div class="chiplegend">Time Text Subtitle</div>
        <div class="chipbigy" v-if="cpl.time_text_subtitle_encrypted">YES</div>
        <div class="chipbigb" v-else>NO</div>
      </div>
      <div class="chip">
        <div class="chiplegend">Markers</div>
        <div class="chipbigb">{{ yesNo(cpl.marker_list > 0) }}</div>
      </div>
      <div class="chip" v-for="(v, index) in cpl.chip_list" :key="index">
        <div class="chiplegend">{{ v.key }}</div>
        <div v-bind:class="v.class">{{ v.value }}</div>
      </div>
    </center>
    <br /><br />
    <table class="table table-sm">
      <tbody>
        <tr>
          <td>Issuer</td>
          <td>{{ getValue(cpl.Issuer) }}</td>
        </tr>
        <tr>
          <td>Creatore</td>
          <td>{{ getValue(cpl.Creator) }}</td>
        </tr>
        <tr>
          <td>Issue Date</td>
          <td>{{ getValue(cpl.IssueDate) }}</td>
        </tr>
        <tr v-if="cpl.ContentVersion">
          <td>Label Text</td>
          <td>{{ getValue(cpl.ContentVersion.LabelText) }}</td>
        </tr>
        <tr>
          <td>Content Kind</td>
          <td>{{ contentKind(cpl.ContentKind) }}</td>
        </tr>
        <tr>
          <td>DCP Type</td>
          <td>{{ cpl.content_type }}</td>
        </tr>
        <tr>
          <td>Namespace</td>
          <td>{{ cpl.$.xmlns }}</td>
        </tr>
        <tr>
          <td>Duration</td>
          <td>{{ cpl.duration.tc }} [{{ cpl.duration.f }} frames]</td>
        </tr>
        <tr>
          <td>Edit Rate | Frame Rate</td>
          <td>{{ cpl.edit_rate }} FPS | {{ cpl.frame_rate }} FPS</td>
        </tr>
        <tr>
          <td>Screen Aspect Ratio</td>
          <td>{{ getValue(cpl.aspect_ratio.s) }}</td>
        </tr>
        <tr>
          <td><br /><br /></td>
          <td></td>
        </tr>
        <tr v-for="(v, index) in cpl.chip_list" :key="index">
          <td>{{ v.key }}</td>
          <td>{{ v.value }}</td>
        </tr>
        <tr>
          <td>Signature</td>
          <td>{{ yesNo(cpl.signature) }}</td>
        </tr>
        <tr>
          <td>Timed Text Subtitles</td>
          <td>
            {{ yesNo(cpl.time_text_subtitle) }}
            <span v-if="cpl.time_text_subtitle">
              | encryption : {{ yesNo(cpl.time_text_subtitle_encrypted) }}
            </span>
          </td>
        </tr>
        <tr>
          <td>Rating Agency & Label</td>
          <td>
            {{ yesNo(this.cpl.rating) }}
          </td>
        </tr>
        <tr>
          <td>Composition Metadata Asset</td>
          <td>
            {{ yesNo(cpl.composition_metadata) }}
            <span v-if="cpl.composition_metadata_extension">
              with metadata extension</span
            >
          </td>
        </tr>
        <tr>
          <td>Markers</td>
          <td>{{ yesNo(cpl.marker_list.length > 0) }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="cpl.content_type == 'SMPTE'">
      <MarkersInfo
        v-if="cpl.marker_list.length > 0"
        v-bind:marker_list="cpl.marker_list"
      ></MarkersInfo>
      <h3>DCP Test</h3>
      <div class="show_cpl_test_help">
        <div class="cpl_test_help">
          <p>
            Testing Activities •
            <a href="http://www.isdcf.com" class="href" target="_blank"
              >ISDCF</a
            >
            and
            <a href="http://www.edcf.net" class="href" target="_blank">EDCF</a>
            are established working groups supporting the rollout of digital
            cinema and aid in the
            <a href="https://www.smptedcp.com" class="href" target="_blank"
              >SMPTE DCP transition</a
            >.
          </p>
        </div>
      </div>
      <center>
        <div class="chip">
          <div class="chiplegend">ISDCF</div>
          <div class="chipbigy">{{ cpl.test.ISDCF }}</div>
        </div>
        <div
          class="chip"
          v-if="cpl.test.ISDCF == 'SMPTE B' && cpl.test.EDCF != 'KO'"
        >
          <div class="chiplegend"></div>
          <div class="chipbigy">EDCF</div>
        </div>
        <div
          class="chip"
          v-if="cpl.test.ISDCF == 'SMPTE B' && cpl.test.CST != 'KO'"
        >
          <div class="chiplegend"></div>
          <div class="chipbigy">CST</div>
        </div>
      </center>
    </div>
    <br />
    <template v-if="hasKey(cpl, 'ReelList')">
      <h3>Reel List</h3>
      <TimeLine v-bind:ReelList="cpl.ReelList"></TimeLine>
      <div v-for="(reel, index) in cpl.ReelList" :key="index">
        <template
          v-for="(asset, assetindex) in reel.AssetList"
          :key="assetindex"
        >
          <div v-if="hasKey(asset, 'CompositionMetadataAsset')">
            <h4
              v-if="CompositionMetaDataModal"
              data-dismiss="modal"
              @click="CompositionMetaDataModal = false"
            >
              Composition Metadata Asset
              <small><i class="bi bi-file-minus"></i></small>
            </h4>
            <h4 v-else @click="CompositionMetaDataModal = true">
              Composition Metadata Asset
              <small><i class="bi bi-file-plus"></i></small>
            </h4>
            <div v-if="CompositionMetaDataModal">
              <p>
                Full Content Title Text : Fully readable content title<br />
                Release Territory : ISO Territory reference for content
                release<br />
                Version & Number Temp : Pre-release or Final Release status plus
                version number<br />
                Chain Fully : Fully readable name of chain or specific event (if
                required)<br />
                Distributor : Fully readable name of content distributor<br />
                Facility : Fully readable name of mastering facility<br />
                Alternate Content Version List : Alternative Content Version
                UUID for content databases & Client Version IDs<br />
                Luminance : Light level of picture recorded in Foot Lambert or
                Candela per sq.m units<br />
                Picture Area / Active Area : Pixel dimensions of Stored (full)
                and Active (visible) picture (eg pillar/letterboxed)<br />
                Sound Config / Sample Rate : Fully readable details of audio
                configuration and sample rate<br />
                Subtitle Language (Open/Closed) : Fully readable details of open
                and closed subtitle languages<br />
              </p>
              <br />
              <SimplePrettyPrint
                v-bind:data="asset.CompositionMetadataAsset"
                v-bind:indentation="0"
              ></SimplePrettyPrint>
            </div>
            <br />
          </div>
        </template>
      </div>
      <br />
    </template>
  </div>
</template>

<script>
/* FishTank 2023 */
/* DPC CPL parsing component */
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../../firebase/index";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const firbaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firbaseApp);
const firbaseDatabase = getDatabase(firbaseApp);

import config from "../tools/config.json";
import {
  contentkind,
  get_editrate,
  get_timecode,
  pictureformat,
  aspect_ratio_decimal,
  get_value,
  has_key,
  clean_object,
  to_array,
  yesno,
  clean_key,
} from "../tools/utils";
import { format_dcp_pdf } from "../tools/pdf";

export default {
  name: "DcpCpl",
  props: ["mainindex"],
  data() {
    return {
      MarkerModal: false,
      CompositionMetaDataModal: false,
      CompositionModal: false,
      isready: false,
      iscpl: false,
      cpl: {},
      id: null,
    };
  },
  created: function () {
    if (!this.$store.state.files[this.mainindex].check) this.parseCpl();
  },
  methods: {
    hasKey: function (x, y) {
      return has_key(x, y);
    },
    yesNo: function (value) {
      return yesno(value);
    },
    getValue: function (value) {
      return get_value(value);
    },
    contentKind: function (value) {
      return get_value(contentkind(value));
    },
    cleanKeyRoot: function (value) {
      return clean_key(value).replace(" :", "");
    },
    pictureFormat: function (value) {
      return pictureformat(value);
    },
    parseCpl() {
      if (
        Object.keys(this.$store.state.files[this.mainindex].data) ==
          "CompositionPlaylist" &&
        this.$store.state.files[this.mainindex].check == false
      ) {
        this.cpl =
          this.$store.state.files[this.mainindex].data.CompositionPlaylist; //DEEPCLONE
        this.xmlnsTest();
      }
      if (this.iscpl) {
        this.cpl.fileName = this.$store.state.files[this.mainindex].name;
        this.cpl.time_text_subtitle_encrypted = false;
        this.cpl.composition_metadata = false;
        this.cpl.composition_metadata_extension = false;
        this.cpl.aspect_ratio = { d: 0, s: "0x0" };
        this.cpl.duration = { f: 0, tc: "" };
        this.cpl.time_text_subtitle = false;
        this.cpl.stereoscopic = false;
        this.cpl.signature = false;
        this.cpl.marker_list = [];
        this.cpl.rating = false;
        this.cpl.frame_rate = 0;
        this.cpl.chip_list = [];
        this.cpl.edit_rate = 0;
        this.cpl.hfr = false;

        if (has_key(this.cpl, "Signer") && has_key(this.cpl, "Signature")) {
          this.cpl.signature = true;
        }

        if (has_key(this.cpl, "RatingList")) {
          this.cpl.rating = true;
        }

        this.reel_parse();
        this.smpte_test();
        this.isready = true;

        setTimeout(() => {
          this.updateCplBigData();
        }, (Math.floor(Math.random() * 40) + 1) * 100 + 1000);

        this.$store.state.files[this.mainindex].check = true;
        this.$store.state.files[this.mainindex].label = get_value(
          this.cpl.ContentTitleText
        );
        this.$store.state.files[this.mainindex].package = "DCP";
        this.$store.state.files[this.mainindex].type = "CPL";
        this.$store.state.files[this.mainindex].uuid = get_value(this.cpl.Id);
        this.$store.state.files[this.mainindex].info = {
          edit_rate: this.cpl.edit_rate,
          reel_list: this.cpl.ReelList.length,
          aspect_ratio: get_value(this.cpl.aspect_ratio.d),
          duration: this.cpl.duration.tc,
          content_kind: this.contentKind(this.cpl.ContentKind),
          content_type: this.cpl.content_type,
          stereoscopic: this.pictureFormat(this.cpl.stereoscopic),
        };
      }
    },
    pdfExport() {
      pdfMake
        .createPdf(format_dcp_pdf(this.cpl))
        .download(`cpl_translator_${this.cpl.ContentTitleText}.pdf`);
    },
    updateCplBigData() {
      let data = {};
      const currentUserData = ref(
        firbaseDatabase,
        `users/${firebaseAuth.currentUser.uid}`
      );
      onValue(
        currentUserData,
        (snapshot) => {
          if (snapshot.exists) {
            data = snapshot.val();
            let er = `_${String(this.cpl.edit_rate)
              .replace(".", "_")
              .replace("#", "_")
              .replace("$", "_")
              .replace("/", "_")
              .replace("]", "_")
              .replace("[", "_")}`;
            if (has_key(data.dcp.edit_rate, er)) {
              data.dcp.edit_rate[er] += 1;
            } else {
              data.dcp.edit_rate[er] = 1;
            }
            let ar = `_${String(this.cpl.aspect_ratio.d)
              .replace(".", "_")
              .replace("#", "_")
              .replace("$", "_")
              .replace("/", "_")
              .replace("]", "_")
              .replace("[", "_")}`;
            if (has_key(data.dcp.aspect_ratio, ar)) {
              data.dcp.aspect_ratio[ar] += 1;
            } else {
              data.dcp.aspect_ratio[ar] = 1;
            }

            if (this.cpl.stereoscopic) {
              data.dcp.picture_format.stereo += 1;
            } else {
              data.dcp.picture_format.mono += 1;
            }
            if (this.cpl.content_type === "IOP") {
              data.dcp.iop += 1;
            }
            if (this.cpl.content_type === "SMPTE") {
              data.dcp.smpte += 1;
              this.cpl.chip_list.forEach((element) => {
                if (element.value == "Dolby Atmos") {
                  data.dcp.smpte_test.dolby_atmos += 1;
                }
              });
              if (this.cpl.test.ISDCF === "SMPTE B") {
                data.dcp.smpte_test.b += 1;
              }
              if (this.cpl.test.ISDCF === "SMPTE A") {
                data.dcp.smpte_test.a += 1;
              }
              if (this.cpl.test.CST === "OK") {
                data.dcp.smpte_test.cst += 1;
              }
              if (this.cpl.test.EDCF === "OK") {
                data.dcp.smpte_test.edcf += 1;
              }
            }
            data.dcp.total += 1;
            data.use += 1;
            set(currentUserData, data);
          }
        },
        {
          onlyOnce: true,
        }
      );
    },
    xmlnsTest() {
      if (this.cpl.$.xmlns == config.xmlns.smpte_cpl) {
        this.cpl.content_type = "SMPTE";
        this.iscpl = true;
      }
      if (this.cpl.$.xmlns == config.xmlns.interop_cpl) {
        this.cpl.content_type = "IOP";
        this.iscpl = true;
      }
    },
    smpte_test() {
      this.cpl.test = {};
      // only for SMPTE DCP
      if (this.cpl.content_type == "SMPTE") {
        this.cpl.test.ISDCF = "KO";
        this.cpl.test.EDCF = "KO";
        this.cpl.test.CST = "KO";
        // ISDCF TEST
        if (
          this.cpl.rating &&
          this.cpl.marker_list > 0 &&
          this.cpl.composition_metadata
        ) {
          this.cpl.test.ISDCF = "SMPTE B";
        } else {
          this.cpl.test.ISDCF = "SMPTE A";
        }
        if (
          this.cpl.test.ISDCF == "SMPTE A" &&
          this.cpl.time_text_subtitle_encrypted
        ) {
          this.cpl.test.ISDCF = "SMPTE B";
        }
        // CST
        if (
          this.cpl.test.ISDCF == "SMPTE B" &&
          this.cpl.time_text_subtitle_encrypted == false &&
          this.cpl.composition_metadata_extension
        ) {
          this.cpl.test.CST = "OK";
        }
        // EDCF
        if (this.cpl.edit_rate == 24 && this.cpl.test.CST == "OK") {
          this.cpl.test.EDCF = "OK";
        }
      }
    },
    assetParser(asset) {
      //cleanup information
      for (let [key, value] of Object.entries(asset)) {
        value = get_value(value);
        if (key == "ScreenAspectRatio") value = value.replace(" ", "x");
        asset[key] = value;
      }
      //add custom information
      asset.Duration_tc = get_timecode(
        parseInt(asset.Duration),
        this.cpl.edit_rate
      );
      asset.IntrinsicDuration_tc = get_timecode(
        parseInt(asset.IntrinsicDuration),
        this.cpl.edit_rate
      );
      return asset;
    },
    reel_parse() {
      let encryption_list = [];
      this.cpl.ReelList = to_array(this.cpl.ReelList[0].Reel);
      if (this.cpl.ReelList.length == 0) return;

      this.cpl.ReelList.forEach((reel) => {
        reel.AssetList.forEach((asset, i) => {
          reel.AssetList[i] = clean_object(asset);
        });
        reel.AssetList.forEach((asset) => {
          if (has_key(asset, "MainPicture")) {
            asset.MainPicture.forEach((item) => {
              this.cpl.frame_rate = get_editrate(item.FrameRate);
              this.cpl.edit_rate = get_editrate(item.EditRate);
              this.cpl.duration.f += parseInt(item.Duration);
              this.cpl.aspect_ratio.s = item.ScreenAspectRatio;
              if (has_key(item, "KeyId")) {
                encryption_list.push(1);
              }
              item = this.assetParser(item);
            });
          }
          if (has_key(asset, "MainStereoscopicPicture")) {
            this.cpl.stereoscopic = true;
            asset.MainStereoscopicPicture.forEach((item) => {
              this.cpl.frame_rate = get_editrate(item.FrameRate);
              this.cpl.edit_rate = get_editrate(item.EditRate);
              this.cpl.duration.f += parseInt(item.Duration);
              this.cpl.aspect_ratio.s = item.ScreenAspectRatio;
              if (has_key(item, "KeyId")) {
                encryption_list.push(1);
              }
              item = this.assetParser(item);
            });
          }
          if (has_key(asset, "AuxData")) {
            asset.AuxData.forEach((item) => {
              if (has_key(item, "KeyId")) {
                encryption_list.push(1);
              }
              if (has_key(item, "ns1:DataType")) {
                if (
                  item["ns1:DataType"][0] ==
                  "urn:smpte:ul:060E2B34.04010105.0E090604.00000000"
                ) {
                  item.DataName = "Dolby Atmos";
                  this.cpl.chip_list.push({
                    value: "Dolby Atmos",
                    key: "Auxdata",
                    class: "chipbigb",
                  });
                }
              }
              item = this.assetParser(item);
            });
          }
          if (has_key(asset, "MainClosedCaption")) {
            asset.MainClosedCaption.forEach((item) => {
              if (has_key(item, "KeyId")) {
                this.cpl.time_text_subtitle_encrypted = true;
                encryption_list.push(1);
              }
              asset.ClosedCaption = {};
              item = this.assetParser(item);
              this.cpl.time_text_subtitle = true;
            });
          }
          if (has_key(asset, "ClosedCaption")) {
            asset.ClosedCaption.forEach((item) => {
              if (has_key(item, "KeyId")) {
                this.cpl.time_text_subtitle_encrypted = true;
                encryption_list.push(1);
              }
              item = this.assetParser(item);
              this.cpl.time_text_subtitle = true;
            });
          }
          if (has_key(asset, "MainSubtitle")) {
            asset.MainSubtitle.forEach((item) => {
              if (has_key(item, "KeyId")) {
                this.cpl.time_text_subtitle_encrypted = true;
                encryption_list.push(1);
              }
              item = this.assetParser(item);
              this.cpl.time_text_subtitle = true;
            });
          }
          if (has_key(asset, "MainSound")) {
            asset.MainSound.forEach((item) => {
              if (has_key(item, "KeyId")) {
                encryption_list.push(1);
              }
              item = this.assetParser(item);
            });
          }
          if (has_key(asset, "MainMarkers")) {
            this.cpl.marker_list = asset.MainMarkers.MarkerList.Marker;
          }
          if (has_key(asset, "CompositionMetadataAsset")) {
            this.cpl.composition_metadata = true;
            this.cpl.composition_metadata_extension = has_key(
              asset.CompositionMetadataAsset,
              "ExtensionMetadataList"
            );
          }
        });
      });
      this.cpl.aspect_ratio.d = aspect_ratio_decimal(
        this.cpl.content_type,
        this.cpl.aspect_ratio.s[0]
      );
      this.cpl.duration.tc = get_timecode(
        this.cpl.duration.f,
        this.cpl.edit_rate
      );
      // HFR
      if (this.cpl.stereoscopic) {
        if (this.cpl.edit_rate >= 48) {
          this.cpl.hfr = true;
        }
      } else {
        if (this.cpl.edit_rate >= 60) {
          this.cpl.hfr = true;
        }
      }
      if (encryption_list.length > 0) {
        this.cpl.chip_list.push({
          value: "YES",
          key: "Encryption",
          class: "chipbigg",
        });
      } else {
        this.cpl.chip_list.push({
          value: "NO",
          key: "Encryption",
          class: "chipbigr",
        });
      }
      this.cpl.chip_list = [
        ...new Map(
          this.cpl.chip_list.map((item) => [item["value"], item])
        ).values(),
      ];
    },
  },
};
</script>
