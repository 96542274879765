<!-- FishTank 2023-->
<script setup>
import DcpCpl from "./parser/DcpCpl.vue";
import ImpCpl from "./parser/ImpCpl.vue";
import PackageList from "./parser/PackageList.vue";
import MainDescription from "./MainDescription.vue";
</script>

<template lang="html">
  <nav
    class="navbar navbar-dark bg-dark navbar-expand-lg py-2 sticky-top"
    v-if="files().length >= 1"
  >
    <div class="container-fluid">
      <div class="collapse navbar-collapse">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <input
              multiple
              type="file"
              ref="fileuploadtop"
              class="FileUploadTop"
              accept=".xml"
              @change="onChange($event)"
            />
            <div class="DropZoneTop">
              Add XML file : DCP (CPL,PKL), IMP (CPL, PKL, OPL)
            </div>
          </li>
          <li class="nav-item" v-if="files().length >= 1">
            <a
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#fileModal"
              >{{ files().length }} <span v-if="files().length > 1">Files</span
              ><span v-else>File</span></a
            >
          </li>
          <li class="nav-item" v-if="packages.length >= 1">
            <a
              class="nav-link"
              data-bs-toggle="modal"
              data-bs-target="#packageModal"
              >{{ packages.length }}
              <span v-if="packages.length > 1">Packages</span
              ><span v-else>Package</span></a
            >
          </li>
        </ul>
        <ul class="navbar-nav d-flex">
          <li class="nav-item">
            <a class="nav-link" @click="reset()">Clear</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="container-fluid bg">
    <!-- File Modal-->
    <div
      class="modal fade"
      id="fileModal"
      tabindex="-1"
      aria-labelledby="fileModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="fileModalLabel">
              {{ files().length }} <span v-if="files().length > 1">Files</span
              ><span v-else>File</span>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              v-for="(file, index) in files()"
              :key="index"
              class="modal_file"
            >
              <template v-if="file.check">
                <a :href="`#${file.uuid}`" class="modal_file_info">
                  {{ file.label }}</a
                >
                <br />
                <span class="modal_file_chip"
                  >{{ file.package }} : {{ file.type }}</span
                >
                <template v-if="file.package == 'DCP' && file.type == 'CPL'">
                  <span class="modal_file_chip"
                    >{{ file.info.content_type }}
                  </span>
                  <span class="modal_file_chip"
                    >Edit Rate : {{ file.info.edit_rate }}
                  </span>
                  <span class="modal_file_chip"
                    >Reels : {{ file.info.reel_list }}
                  </span>
                  <span class="modal_file_chip"
                    >Aspect Ratio : {{ file.info.aspect_ratio }}
                  </span>
                  <span class="modal_file_chip"
                    >Duration : {{ file.info.duration }}
                  </span>
                  <span class="modal_file_chip"
                    >Content Kind : {{ file.info.content_kind }}
                  </span>
                  <span class="modal_file_chip"
                    >Picture Format : {{ file.info.stereoscopic }}
                  </span>
                </template>
                <template v-if="file.package == 'IMP' && file.type == 'CPL'">
                  <span class="modal_file_chip"
                    >Edit Rate : {{ file.info.edit_rate }}
                  </span>
                  <span class="modal_file_chip"
                    >Segments : {{ file.info.segments }}
                  </span>
                  <span class="modal_file_chip"
                    >Duration : {{ file.info.duration }}
                  </span>
                  <span class="modal_file_chip"
                    >Content Kind : {{ file.info.content_kind }}
                  </span>
                </template>
                <template v-if="file.type == 'PKL'">
                  <span class="modal_file_chip"
                    >Assets : {{ file.info.asset_count }}
                  </span>
                  <span class="modal_file_chip"
                    >Size : {{ file.info.size }}
                  </span>
                </template>
              </template>
              <template v-else>
                {{ file.name }}
                <br />
              </template>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Package Modal-->
    <div
      class="modal fade"
      id="packageModal"
      tabindex="-1"
      aria-labelledby="packageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="packageModalLabel">
              {{ packages.length }}
              <span v-if="packages.length > 1">Packages</span
              ><span v-else>Package</span>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div
              v-for="(pack, index) in packages"
              :key="index"
              class="modal_file"
            >
              {{ pack.name }} :
              <template v-for="(file, index) in files()" :key="index">
                <template
                  v-if="
                    file.check &&
                    (file.uuid == pack.cpl || file.uuid == pack.pkl)
                  "
                >
                  <span class="modal_file_chip">
                    <a :href="`#${file.uuid}`" class="modal_file_info">
                      {{ file.package }} : {{ file.type }}</a
                    >
                  </span>
                </template>
              </template>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="files().length == 0">
      <center>
        <div class="DropZone">
          <input
            multiple
            type="file"
            ref="fileupload"
            class="FileUpload"
            accept=".xml"
            @change="onChange($event)"
          />
          <br />
          Open or Drag-and-Drop DCP, IMP XML files.
        </div>
        <MainDescription></MainDescription>
      </center>
    </div>
    <template v-for="(file, index) in files()" :key="index">
      <DcpCpl v-bind:mainindex="index"></DcpCpl>
      <ImpCpl v-bind:mainindex="index"></ImpCpl>
      <PackageList v-bind:mainindex="index"></PackageList>
    </template>
  </div>
</template>

<script>
import xml2js from "xml2js";
const ONE_SECOND = 1000;

export default {
  name: "FileParser",
  data() {
    return {
      intervales: [],
      packages: [],
    };
  },
  methods: {
    files() {
      return this.$store.state.files;
    },
    reset() {
      this.$store.state.files = [];
      this.packages = [];
    },
    onChange(event) {
      this.$notify({
        text: "Loading ...",
        type: "info",
        fullWidth: true,
        duration: 300,
      });
      for (let index = 0; index < event.target.files.length; index++) {
        const reader = new FileReader();
        const newfile = event.target.files[index];
        if (newfile.type == "text/xml") {
          let to_add = this.$store.state.files.filter((file) => {
            return file.name == newfile.name;
          });
          if (to_add.length == 0) {
            reader.onload = (res) => {
              let file = { name: newfile.name, check: false };
              this.parseXML(res.target.result).then((data) => {
                if (data == null) return;
                file.data = data;
              });
              this.$store.state.files.push(file);
            };
            reader.onerror = (err) => {
              this.$notify({
                text: err,
                type: "error",
                fullWidth: true,
                duration: 3000,
              });
            };
            reader.readAsText(newfile);
          }
        }
      }
    },
    parseXML(data) {
      return new Promise((resolve) => {
        let parser = new xml2js.Parser({
          trim: true,
          explicitArray: true,
        });
        parser.parseString(data, function (err, result) {
          resolve(result);
        });
      });
    },
  },
  created() {
    this.intervales.push(
      setInterval(() => {
        this.packages = [];
        this.$store.state.files.forEach((pkl) => {
          if (pkl.type == "PKL") {
            pkl.assets.forEach((asset) => {
              this.$store.state.files.forEach((cpl) => {
                if (cpl.type == "CPL" && cpl.uuid == asset) {
                  this.packages.push({
                    pkl: pkl.uuid,
                    cpl: cpl.uuid,
                    name: cpl.label,
                  });
                }
              });
            });
          }
        });
      }, 2 * ONE_SECOND)
    );
  },
  beforeUnmount() {
    this.reset();
    this.intervales.forEach((intervale) => {
      clearInterval(intervale);
    });
  },
};
</script>
