<template lang="html">
  <div
    class="side"
    v-if="ispkl"
    :key="getValue(pkl.Id)"
    v-bind:id="getValue(pkl.Id)"
  >
    <div v-if="content_type == 'IMP'" class="imp_logo"></div>
    <div v-if="content_type == 'SMPTE'" class="smpte_logo"></div>
    <div v-if="content_type == 'IOP'" class="interop_logo"></div>
    <h3>PKL - Main Information</h3>
    <table class="table table-sm">
      <tbody>
        <tr>
          <td>Annotation Text</td>
          <td>{{ getValue(pkl.AnnotationText) }}</td>
        </tr>
        <tr>
          <td>IssueDate</td>
          <td>{{ getValue(pkl.IssueDate) }}</td>
        </tr>
        <tr>
          <td>File Name</td>
          <td>{{ getValue(pkl.fileName) }}</td>
        </tr>
        <tr>
          <td>UUID</td>
          <td>{{ getValue(pkl.Id) }}</td>
        </tr>
        <tr>
          <td>Issuer</td>
          <td>{{ getValue(pkl.Issuer) }}</td>
        </tr>
        <tr>
          <td>Creator</td>
          <td>{{ getValue(pkl.Creator) }}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <h3>Details</h3>
    <center>
      <div class="chip">
        <div class="chiplegend">No of asset refered</div>
        <div class="chipbigb">{{ pkl.asset_count }}</div>
      </div>
      <div class="chip">
        <div class="chiplegend">Total Size</div>
        <div class="chipbigb">{{ humanSize(pkl.size) }}</div>
      </div>
      <div class="chip">
        <div class="chiplegend">Signature</div>
        <div class="chipbigr" v-if="pkl.signature == true">YES</div>
        <div class="chipbigg" v-if="pkl.signature == false">NO</div>
      </div>
    </center>
    <br />
    <h3>Time Transferts <small>Standard speeds</small></h3>
    <div class="row" v-for="(transfert, name) in transferts" :key="name">
      <div class="col-sm-3">{{ transfert.legend }}</div>
      <div class="col-sm-2">
        <h4>{{ transfert.human_biterate }}</h4>
      </div>
      <div class="col-sm-2">
        <h4>{{ humanTime(transfert.time) }} <small></small></h4>
      </div>
    </div>
    <br />
    <h3>Assets List</h3>
    <div class="row" v-for="(assetList, id) in pkl.AssetList" :key="id">
      <template v-for="(asset, id) in assetList.Asset" :key="id">
        <div class="col-sm-1"></div>
        <div class="col-sm-2">
          <h4>{{ humanSize(asset.Size) }}</h4>
        </div>
        <div class="col-sm-9">
          <b>{{ getValue(asset.Id) }}</b
          ><br />
          <span v-if="hasKey(asset, 'OriginalFileName')"
            ><b>File :</b> {{ getValue(asset.OriginalFileName) }}<br
          /></span>
          <b>Type :</b> {{ getValue(asset.Type) }}<br />
          <b>Hash :</b> {{ getValue(asset.Hash) }}<br />
        </div>
        <hr />
      </template>
    </div>
  </div>
</template>

<script>
/* FishTank 2023 */
/* DPC & IMP PKL component */
import firebase from "firebase/compat/app";
import { firebaseConfig } from "../../firebase/index";
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";

const firbaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firbaseApp);
const firbaseDatabase = getDatabase(firbaseApp);

import config from "../tools/config.json";
import { humantime, humansize, get_value, has_key } from "../tools/utils";

export default {
  name: "PackageList",
  props: ["mainindex"],
  data() {
    return {
      ispkl: false,
      transfer: [],
      pkl: {},
      asset_uuid: [],
      content_type: null,
    };
  },
  created: function () {
    if (!this.$store.state.files[this.mainindex].check) this.parsePkl();
  },
  methods: {
    hasKey: function (x, y) {
      return has_key(x, y);
    },
    getValue: function (value) {
      return get_value(value);
    },
    humanTime: function (value) {
      return humantime(value);
    },
    humanSize: function (value) {
      return humansize(value);
    },
    parsePkl() {
      if (
        Object.keys(this.$store.state.files[this.mainindex].data) ==
          "PackingList" &&
        this.$store.state.files[this.mainindex].check == false
      ) {
        this.pkl = this.$store.state.files[this.mainindex].data.PackingList;
        this.xmlnsTest();
      }
      if (this.ispkl) {
        this.pkl.fileName = this.$store.state.files[this.mainindex].name;
        this.parseAssetList();
        this.getTransfert();

        setTimeout(() => {
          this.updatePklBigData();
        }, (Math.floor(Math.random() * 40) + 1) * 100 + 1000);

        this.$store.state.files[this.mainindex].check = true;
        this.$store.state.files[this.mainindex].label = get_value(
          this.pkl.AnnotationText
        );
        this.$store.state.files[this.mainindex].assets = this.asset_uuid;
        this.$store.state.files[this.mainindex].package = this.package;
        this.$store.state.files[this.mainindex].type = "PKL";
        this.$store.state.files[this.mainindex].uuid = get_value(this.pkl.Id);
        this.$store.state.files[this.mainindex].info = {
          asset_count: this.pkl.asset_count,
          size: humansize(this.pkl.size),
        };
      }
    },
    updatePklBigData() {
      let package_size = [0, 1, 5, 10, 15, 30, 50, 100, 150, 200, 250];
      let gb_size = this.pkl.size / 1000000000;
      this.current_size = "";
      if (gb_size >= 250) {
        this.current_size = String(250);
      } else {
        for (let i = 1; i < package_size.length; i++) {
          if (gb_size > package_size[i - 1] && gb_size < package_size[i]) {
            this.current_size = String(package_size[i - 1]);
          }
        }
      }
      let data = {};
      const currentUserData = ref(
        firbaseDatabase,
        "users/" + firebaseAuth.currentUser.uid
      );
      onValue(
        currentUserData,
        (snapshot) => {
          if (snapshot.exists) {
            data = snapshot.val();
            let pkl_size = `_${this.current_size}`;
            if (has_key(data.pkl.size, pkl_size)) {
              data.pkl.size[pkl_size] += 1;
            } else {
              data.pkl.size[pkl_size] = 1;
            }
            data.pkl.total += 1;
            data.use += 1;
            set(currentUserData, data);
          }
        },
        {
          onlyOnce: true,
        }
      );
    },
    xmlnsTest() {
      if (this.pkl.$.xmlns == config.xmlns.smpte_pkl) {
        this.content_type = "SMPTE";
        this.package = "DCP";
        this.ispkl = true;
      }
      if (this.pkl.$.xmlns == config.xmlns.interop_pkl) {
        this.content_type = "IOP";
        this.package = "DCP";
        this.ispkl = true;
      }
      if (this.pkl.$.xmlns == config.xmlns.imp_pkl) {
        this.content_type = "IMP";
        this.package = "IMP";
        this.ispkl = true;
      }
    },
    parseAssetList() {
      this.pkl.asset_count = 0;
      this.asset_uuid = [];
      this.pkl.size = 0;
      this.pkl.signature = false;
      this.pkl.AssetList.forEach((assetList) => {
        assetList.Asset.forEach((asset) => {
          this.pkl.asset_count += 1;
          this.pkl.size += parseFloat(get_value(asset.Size));
          this.asset_uuid.push(get_value(asset.Id));
        });
      });
    },
    getTransfert() {
      this.transferts = {};
      config.transfert.forEach((transfert) => {
        if (transfert.name == "satellite") {
          transfert.time = ((this.pkl.size * 1.1) / transfert.bitrate) * 8;
        } else {
          transfert.time = (this.pkl.size / transfert.bitrate) * 8;
        }
        this.transferts[transfert.name] = transfert;
      });
    },
  },
};
</script>
