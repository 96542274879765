<template lang="html">
  <div>
    <div v-if="isObject(data)" v-bind:style="classObject(0)">
      <div v-for="(value, key) in data" :key="key">
        <span v-if="isString(value) && value != 'cpl-meta'"
          ><b>{{ cleanKey(key) }}</b> {{ value }}</span
        >
        <SimplePrettyPrint
          v-else-if="isObject(value)"
          v-bind:data="value"
          v-bind:indentation="indent + 5"
        ></SimplePrettyPrint>
      </div>
    </div>
  </div>
</template>

<script>
/* FishTank 2023 */
/* 
Auto Pretty Print Object */

import { clean_key } from "./utils";

export default {
  name: "SimplePrettyPrint",
  props: ["data", "indentation"],
  components: {
    SimplePrettyPrint: require("./SimplePrettyPrint.vue"),
  },
  data() {
    return {
      indent: 0,
    };
  },
  created: function () {
    this.indent = this.indentation + 5;
  },
  methods: {
    classObject: function (n) {
      return {
        "text-indent": `${this.indent + n}px`,
      };
    },
    isString: function (x) {
      if (x == "r0" || x == "r1" || x == "r2") {
        return false;
      }
      return (
        String(x) != "[object Object]" &&
        !String(x).startsWith("function") &&
        !Array.isArray(x)
      );
    },
    isObject: function (x) {
      return x instanceof Object;
    },
    cleanKey: function (x) {
      return clean_key(x);
    },
  },
};
</script>
