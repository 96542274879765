import { createApp } from "vue";
import { store, key } from "./store";
import MainView from "./MainView.vue";
import router from "./router";
import Notifications from "@kyvg/vue3-notification";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(MainView);

app.use(router).use(store, key).use(Notifications).mount("#app");
